/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import { getApi } from "../../Repository/Api";
import SectionTitle from "../../Component/SectionTitle";
import { Link } from "react-router-dom";

const AllTransaction = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: "admin/allTransaction",
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const dataList = response?.data?.map((i, index) => [
    `#${index + 1}`,
    i?.date?.slice(0, 10),
    i?.type,
    <span>
      <i className="fa-solid fa-indian-rupee-sign"></i>
      {i?.amount}
    </span>,
    <Link to={`/user-details/${i?.user?._id}`}>
      {i?.user?.fullName ? i?.user?.fullName : i?.user?.email}
    </Link>,
  ]);

  return (
    <section className="section">
      <div className="boxShad">
        <div className="apply">
          <SectionTitle title={"All Transactions"} />

          <TableLayout
            thead={["Sno", "Date", "Type", "Amount", "User"]}
            loading={loading}
            tbody={dataList}
          />
        </div>
      </div>
    </section>
  );
};

export default HOC(AllTransaction);
