/** @format */

import React from "react";
import FullScreenLoader from "./FullScreenLoader";
import NoData from "./NoData";

const TableLayout = ({ thead, tbody, loading }) => {
  return (
    <>
      {loading && <FullScreenLoader loading={loading} />}
      {tbody ? (
        <div className="overflow-X">
          <table className="colored_table">
            <thead>
              <tr>
                {thead?.map((i, index) => (
                  <th key={`thead${index}`}> {i} </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tbody?.map((rowData, rowIndex) => (
                <tr key={`row${rowIndex}`}>
                  {rowData?.map((cellData, cellIndex) => (
                    <td key={`cell${cellIndex}`}>{cellData}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default TableLayout;
