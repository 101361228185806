/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HOC from "../../Component/layout/HOC";
import FullScreenLoader from "../../Component/FullScreenLoader";
import SectionTitle from "../../Component/SectionTitle";
import { getApi } from "../../Repository/Api";

const ViewLegality = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = useCallback(() => {
    getApi({
      url: `static/Legality/${id}`,
      setLoading,
      setResponse: setData,
    });
  }, [id]);

  useEffect(() => {
    fetchHandler();
  }, [fetchHandler]);

  return (
    <section className="section">
      <SectionTitle title={"Legality"} />
      <FullScreenLoader loading={loading} />

      <div className="view-details">
        <p className="value">{data?.data?.legality}</p>
      </div>
    </section>
  );
};

export default HOC(ViewLegality);
