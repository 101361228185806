/** @format */

const endPoints = {
  user: {
    getAll: ({ query = "", page = 1, limit = 10 }) =>
      `user/searchUser?search=${query}&page=${page}&limit=${limit}`,
    export: "admin/exportUsers?userType=User",
    getUserDetail: (id) => `admin/User/${id}`,
  },
  contest: {
    getAll: ({ query = "", page = 1, limit = 10 }) =>
      `user/searchContest?search=${query}&page=${page}&limit=${limit}`,
  },
  banner: {
    getAll: "banner",
    delete: (id) => `banner/${id}`,
    create: "banner",
    editBaneer: (id) => `update/${id}`,
  },
  notification: {
    getAll: "admin/notifications/user",
  },
};

export default endPoints;
