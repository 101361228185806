/** @format */

import React from "react";

const FullScreenLoader = ({ loading }) => {
  return (
    loading && (
      <div className="fullscreen-spinner">
        <div className="spinner"></div>
      </div>
    )
  );
};

export default FullScreenLoader;
