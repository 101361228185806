/** @format */

import React, { useState } from "react";
import HOC from "../../Component/layout/HOC";
import { createApi } from "../../Repository/Api";
import { ClipLoader } from "react-spinners";
import SectionTitle from "../../Component/SectionTitle";

const CreateCallUs = () => {
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");

  const payload = {
    mobileNumber,
    email,
  };

  function payloadRemover() {
    setEmail("");
    setMobileNumber("");
  }

  const additionalFunctions = [payloadRemover];

  const submitHandler = (e) => {
    e.preventDefault();
    createApi({
      url: "admin/call/us",
      payload,
      successMsg: "Created",
      additionalFunctions,
      setLoading,
    });
  };

  return (
    <section className="section">
      <SectionTitle title={"Call Us"} />

      <form className="create-form" onSubmit={submitHandler}>
        <div className="group-input">
          <p>Mobile Number</p>
          <input
            type="tel"
            style={{ maxWidth: "100%" }}
            onChange={(e) => setMobileNumber(e.target.value)}
            value={mobileNumber}
          />
        </div>
        <div className="group-input">
          <p>Email</p>
          <input
            type="email"
            style={{ maxWidth: "100%" }}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>

        <button type="submit">
          {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
        </button>
      </form>
    </section>
  );
};

export default HOC(CreateCallUs);
