/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import HOC from "../../Component/layout/HOC";
import FullScreenLoader from "../../Component/FullScreenLoader";
import SectionTitle from "../../Component/SectionTitle";
import TableLayout from "../../Component/TableLayout";
import { getApi } from "../../Repository/Api";

const ViewRecord = () => {
  const { id } = useParams();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApi({
      url: `series/fantacy/point/${id}/Squard`,
      setResponse,
      setLoading,
    });
  }, [id]);

  const teamAPlayer =
    response?.fantacySquard?.response?.points?.teama?.playing11?.map(
      (i, index) => [
        `#${index + 1}`,
        i?.pid,
        i?.name,
        i?.role,
        i?.rating,
        i?.point,
        i?.starting11,
        i?.run,
        i?.four,
        i?.six,
        i?.sr,
        i?.fifty,
        i?.duck,
        i?.wkts,
        i?.maidenover,
        i?.er,
        i?.catch,
        i?.runoutstumping,
        i?.runoutthrower,
        i?.runoutcatcher,
        i?.directrunout,
        i?.stumping,
        i?.thirty,
        i?.bonus,
        i?.bonuscatch,
        i?.bonusbowedlbw,
      ]
    );

  const teamASub =
    response?.fantacySquard?.response?.points?.teama?.substitute?.map(
      (i, index) => [
        `#${index + 1}`,
        i?.pid,
        i?.name,
        i?.role,
        i?.rating,
        i?.point,
        i?.starting11,
        i?.run,
        i?.four,
        i?.six,
        i?.sr,
        i?.fifty,
        i?.duck,
        i?.wkts,
        i?.maidenover,
        i?.er,
        i?.catch,
        i?.runoutstumping,
        i?.runoutthrower,
        i?.runoutcatcher,
        i?.directrunout,
        i?.stumping,
        i?.thirty,
        i?.bonus,
        i?.bonuscatch,
        i?.bonusbowedlbw,
      ]
    );

  const teamBPlayer =
    response?.fantacySquard?.response?.points?.teamb?.playing11?.map(
      (i, index) => [
        `#${index + 1}`,
        i?.pid,
        i?.name,
        i?.role,
        i?.rating,
        i?.point,
        i?.starting11,
        i?.run,
        i?.four,
        i?.six,
        i?.sr,
        i?.fifty,
        i?.duck,
        i?.wkts,
        i?.maidenover,
        i?.er,
        i?.catch,
        i?.runoutstumping,
        i?.runoutthrower,
        i?.runoutcatcher,
        i?.directrunout,
        i?.stumping,
        i?.thirty,
        i?.bonus,
        i?.bonuscatch,
        i?.bonusbowedlbw,
      ]
    );

  const teamBSub =
    response?.fantacySquard?.response?.points?.teamb?.substitute?.map(
      (i, index) => [
        `#${index + 1}`,
        i?.pid,
        i?.name,
        i?.role,
        i?.rating,
        i?.point,
        i?.starting11,
        i?.run,
        i?.four,
        i?.six,
        i?.sr,
        i?.fifty,
        i?.duck,
        i?.wkts,
        i?.maidenover,
        i?.er,
        i?.catch,
        i?.runoutstumping,
        i?.runoutthrower,
        i?.runoutcatcher,
        i?.directrunout,
        i?.stumping,
        i?.thirty,
        i?.bonus,
        i?.bonuscatch,
        i?.bonusbowedlbw,
      ]
    );

  const thead = [
    "Sno.",
    "ID",
    "Name",
    "Role",
    "Rating",
    "Point",
    "Staring 11",
    "Run",
    "Four",
    "Size",
    "SR",
    "Fifty",
    "Duck",
    "Wickets",
    "Maiden over",
    "ER",
    "Catch",
    "Run out stumping",
    "Run out thrower",
    "Run out catecher",
    "Direct run out",
    "Stumping",
    "Thirty",
    "Bonus",
    "Bonus catch",
    "Bonus bowled lbw",
  ];

  return (
    <section className="section">
      <SectionTitle title={"Fantasy Points"} />
      <FullScreenLoader loading={loading} />

      <div className="view-details">
        <Row>
          <Col xs={12} md={12}>
            <div>
              <p className="label">Team A (Playing 11) </p>
            </div>
          </Col>
          <Col md={12} xs={12} className="mt-2">
            <TableLayout thead={thead} tbody={teamAPlayer} />
          </Col>
          <Col xs={12} md={12}>
            <div>
              <p className="label">Team A (Substitute) </p>
            </div>
          </Col>
          <Col md={12} xs={12} className="mt-2">
            <TableLayout thead={thead} tbody={teamASub} />
          </Col>

          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col xs={12} md={12}>
            <div>
              <p className="label">Team B (Playing 11) </p>
            </div>
          </Col>
          <Col md={12} xs={12} className="mt-2">
            <TableLayout thead={thead} tbody={teamBPlayer} />
          </Col>

          <Col xs={12} md={12}>
            <div>
              <p className="label">Team B (Substitute) </p>
            </div>
          </Col>
          <Col md={12} xs={12} className="mt-2">
            <TableLayout thead={thead} tbody={teamBSub} />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HOC(ViewRecord);
