/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import { getApi } from "../../Repository/Api";
import SectionTitle from "../../Component/SectionTitle";
import FullScreenLoader from "../../Component/FullScreenLoader";

const ReferralPrice = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: "admin/referral-price",
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const dataList = [
    [
      <span>
        <i className="fa-solid fa-indian-rupee-sign"></i>
        {response?.data?.referredByPrice}
      </span>,
      <span>
        <i className="fa-solid fa-indian-rupee-sign"></i>
        {response?.data?.referredUserPrice}
      </span>,
      response?.data?.status === true ? "Active" : "Deactive",
      response?.data?.createdAt?.slice(0, 10),
    ],
  ];

  return (
    <section className="section">
      <div className="boxShad">
        <div className="apply">
          <SectionTitle
            title={"Referral Price"}
            isCreate={true}
            navigationLink="/create-referral-price"
          />

          <FullScreenLoader loading={loading} />

          <TableLayout
            thead={[
              "Referred By Price",
              "Referred User Price",
              "Status",
              "Created At",
            ]}
            tbody={dataList}
          />
        </div>
      </div>
    </section>
  );
};

export default HOC(ReferralPrice);
