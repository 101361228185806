/** @format */

import React, { useState } from "react";
import HOC from "../../Component/layout/HOC";
import { createApi } from "../../Repository/Api";
import { ClipLoader } from "react-spinners";
import SectionTitle from "../../Component/SectionTitle";

const CreateAboutUs = () => {
  const [loading, setLoading] = useState(false);
  const [aboutUs, setAboutUs] = useState("");

  const payload = {
    aboutUs,
  };

  function payloadRemover() {
    setAboutUs("");
  }

  const additionalFunctions = [payloadRemover];

  const submitHandler = (e) => {
    e.preventDefault();
    createApi({
      url: "static/createAboutus",
      payload,
      successMsg: "Created",
      additionalFunctions,
      setLoading,
    });
  };

  return (
    <section className="section">
      <SectionTitle title={"About Us"} />

      <form className="create-form" onSubmit={submitHandler}>
        <div className="group-input">
          <p>Description</p>
          <input
            type="text"
            style={{ maxWidth: "100%" }}
            onChange={(e) => setAboutUs(e.target.value)}
            value={aboutUs}
          />
        </div>

        <button type="submit">
          {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
        </button>
      </form>
    </section>
  );
};
export default HOC(CreateAboutUs);
