/** @format */
import { Routes, Route } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import User from "./pages/User/User";
import Login from "./pages/Login";
import Cricket from "./pages/Cricket/Cricket";
import Contest from "./pages/Contest/Contest";
import Tournament from "./pages/Tournament/Tournament";
import UserDetail from "./pages/User/UserDetail";
import CreateContest from "./pages/Contest/CreateContest";
import CreateTournament from "./pages/Tournament/CreateTournament";
import HowToPlay from "./pages/HowToPlay/HowToPlay";
import CreateHowPlay from "./pages/HowToPlay/CreateHowPlay";
import AboutUs from "./pages/AboutUs/AboutUs";
import CreateAboutUs from "./pages/AboutUs/CreateAboutUs";
import ViewAboutUs from "./pages/AboutUs/ViewAboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import CreatePrivacyPolicy from "./pages/PrivacyPolicy/CreatePrivacyPolicy";
import ViewPolicy from "./pages/PrivacyPolicy/ViewPolicy";
import Terms from "./pages/Terms/Terms";
import CreateTerms from "./pages/Terms/CreateTerms";
import ViewTerms from "./pages/Terms/ViewTerms";
import Legality from "./pages/Legality/Legality";
import CreateLegality from "./pages/Legality/CreateLegality";
import ViewLegality from "./pages/Legality/ViewLegality";
import FAQ from "./pages/FAQ/FAQ";
import CreateFaq from "./pages/FAQ/CreateFaq";
import ViewFaq from "./pages/FAQ/ViewFaq";
import EditFaq from "./pages/FAQ/EditFaq";
import CallUs from "./pages/CallUs/CallUs";
import CreateCallUs from "./pages/CallUs/CreateCallUs";
import FantasyHelp from "./pages/FantasyHelp/FantasyHelp";
import ViewFantasyHelp from "./pages/FantasyHelp/ViewFantasyHelp";
import CreateFantasyHelp from "./pages/FantasyHelp/CreateFantasyHelp";
import ResGame from "./pages/ResGame/ResGame";
import ViewResGame from "./pages/ResGame/ViewResGame";
import CreateResGame from "./pages/ResGame/CreateResGame";
import Offer from "./pages/Offer/Offer";
import CreatOffer from "./pages/Offer/CreatOffer";
import UpdateOffer from "./pages/Offer/UpdateOffer";
import ViewMatch from "./pages/Cricket/ViewMatch";
import ViewRecord from "./pages/Cricket/ViewRecord";
import ViewContest from "./pages/Contest/ViewContest";
import EditContest from "./pages/Contest/EditContest";
import ViewContestSetting from "./pages/Tournament/ViewContestSetting";
import EditContestSetting from "./pages/Tournament/EditContestSetting";
import UserTeam from "./pages/User/UserTeam";
import ViewTeam from "./pages/User/ViewTeam";
import GetTDS from "./pages/TDS/GetTDS";
import CreateTDS from "./pages/TDS/CreateTDS";
import UpdateTDS from "./pages/TDS/UpdateTDS";
import GST from "./pages/GST/GST";
import CreateGst from "./pages/GST/CreateGst";
import UpdateGst from "./pages/GST/UpdateGst";
import Notification from "./pages/Notification/Notification";
import CreateNotification from "./pages/Notification/CreateNotification";
import ReferralPrice from "./pages/Referral/ReferralPrice";
import CreateRefferal from "./pages/Referral/CreateRefferal";
import AllTransaction from "./pages/Transaction/AllTransaction";
import CreditTransaction from "./pages/Transaction/CreditTransaction";
import DebitTransaction from "./pages/Transaction/DebitTransaction";
import UserTransaction from "./pages/Transaction/UserTransaction";
import Banner from "./pages/Banner/Banner";
import CreateBanner from "./pages/Banner/CreateBanner";
import EditBanner from "./pages/Banner/EditBanner";

function App() {
  return (
    <>
      <ReactNotifications />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user" element={<User />} />
        <Route path="/cricket" element={<Cricket />} />
        <Route path="/contest" element={<Contest />} />
        <Route path="/tournament" element={<Tournament />} />
        <Route path="/user-details/:id" element={<UserDetail />} />
        <Route path="/create-contest/:id" element={<CreateContest />} />
        <Route path="/create-treatment" element={<CreateTournament />} />
        <Route path="/how-to-play" element={<HowToPlay />} />
        <Route path="/create-how-to-play" element={<CreateHowPlay />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/create-about-us" element={<CreateAboutUs />} />
        <Route path="/view-about-us/:id" element={<ViewAboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/create-privacy-policy"
          element={<CreatePrivacyPolicy />}
        />
        <Route path="/view-privacy-policy/:id" element={<ViewPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/create-terms" element={<CreateTerms />} />
        <Route path="/view-term/:id" element={<ViewTerms />} />
        <Route path="/legality" element={<Legality />} />
        <Route path="/create-legality" element={<CreateLegality />} />
        <Route path="/view-legality/:id" element={<ViewLegality />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/create-faq" element={<CreateFaq />} />
        <Route path="/view-faq/:id" element={<ViewFaq />} />
        <Route path="/edit-faq/:id" element={<EditFaq />} />
        <Route path="/call-us" element={<CallUs />} />
        <Route path="/create-callUs" element={<CreateCallUs />} />
        <Route path="/fantasy-help" element={<FantasyHelp />} />
        <Route path="/view-fantasy-help/:id" element={<ViewFantasyHelp />} />
        <Route path="/create-fantasy-help" element={<CreateFantasyHelp />} />
        <Route path="/responsible-game" element={<ResGame />} />
        <Route path="/view-responsible-game/:id" element={<ViewResGame />} />
        <Route path="/create-responsible-game" element={<CreateResGame />} />
        <Route path="/offer" element={<Offer />} />
        <Route path="/create-offer" element={<CreatOffer />} />
        <Route path="/update-offer/:id" element={<UpdateOffer />} />
        <Route path="/view-match/:id" element={<ViewMatch />} />
        <Route path="/record/:id" element={<ViewRecord />} />
        <Route path="/view-contest/:id" element={<ViewContest />} />
        <Route path="/edit-contest/:id" element={<EditContest />} />
        <Route
          path="/view-contest-setting/:id"
          element={<ViewContestSetting />}
        />
        <Route
          path="/edit-contest-setting/:id"
          element={<EditContestSetting />}
        />
        <Route path="/user-team/:id" element={<UserTeam />} />
        <Route path="/view-team/:id" element={<ViewTeam />} />
        <Route path="/tds" element={<GetTDS />} />
        <Route path="/create-tds" element={<CreateTDS />} />
        <Route path="/edit-tds/:id" element={<UpdateTDS />} />
        <Route path="/gst" element={<GST />} />
        <Route path="/create-gst" element={<CreateGst />} />
        <Route path="/update-gst/:id" element={<UpdateGst />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/create-notification" element={<CreateNotification />} />
        <Route path="/referral-price" element={<ReferralPrice />} />
        <Route path="/create-referral-price" element={<CreateRefferal />} />
        <Route path="/transaction" element={<AllTransaction />} />
        <Route path="/credit-transaction" element={<CreditTransaction />} />
        <Route path="/debit-transaction" element={<DebitTransaction />} />
        <Route path="/user-transaction/:id" element={<UserTransaction />} />
        <Route path="/banner" element={<Banner />} />
        <Route path="/create-banner" element={<CreateBanner />} />
        <Route path="/edit-banner/:id" element={<EditBanner />} />
      </Routes>
    </>
  );
}

export default App;
