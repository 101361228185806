/** @format */

import React, { useEffect, useState, useCallback } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import SectionTitle from "../../Component/SectionTitle";
import { Link } from "react-router-dom";
import FullScreenLoader from "../../Component/FullScreenLoader";
import endPoints from "../../Repository/apiRoutes";
import { CustomePagination } from "../../Component/HelpingComponent/HelpingComponent";
import { debouncedSetQuery } from "../../utils/utils";

const User = () => {
  const [data, setData] = useState({});
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);

  const fetchHandler = useCallback(() => {
    getApi({
      url: endPoints.user.getAll({ query, page, limit }),
      setResponse: setData,
      setLoading,
    });
  }, [query, page, limit]);

  useEffect(() => {
    fetchHandler();
  }, [fetchHandler]);

  const deleteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `admin/User/${id}`,
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  const userList = data?.data?.docs
    ?.slice()
    ?.reverse()
    ?.map((i) => [
      i?.fullName,
      i?.email,
      i?.phone,
      `${i?.city} , ${i?.state}`,
      i?.createdAt?.slice(0, 10),
      <Link to={`/user-team/${i._id}`}>View</Link>,
      <Link to={`/user-transaction/${i._id}`}>View</Link>,
      <DropDown
        viewLink={`/user-details/${i._id}`}
        deleteHandle={() => deleteHandler(i._id)}
      />,
    ]);

  // Export Data
  const exportData = () => {
    const downloadWord = () => {
      window.location.href = "https://9gamefire.com/api/userData";
    };
    getApi({
      url: endPoints.user.export,
      setLoading,
      additionalFunctions: [downloadWord],
    });
  };

  return (
    <>
      <FullScreenLoader loading={loading} />
      <section className="section">
        <div className="boxShad">
          <div className="apply">
            <SectionTitle title={"User Management"} />
            <div className="search-container-with">
              <div className="search-container">
                <input
                  type={"search"}
                  placeholder="Search"
                  onChange={(e) => debouncedSetQuery(e.target.value, setQuery)}
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <button className="download-btn" onClick={exportData}>
                Download
              </button>
            </div>

            <TableLayout
              thead={[
                "Name",
                "Email",
                "Phone",
                "Address",
                "Join Date",
                "Team",
                "Transaction",
                "",
              ]}
              tbody={userList}
            />
            <CustomePagination
              page={page}
              setPage={setPage}
              setLimit={setLimit}
              limit={limit}
              nextPage={data?.data?.hasNextPage}
              prevPage={data?.data?.hasPrevPage}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HOC(User);
