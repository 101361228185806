/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FullScreenLoader from "../../Component/FullScreenLoader";
import HOC from "../../Component/layout/HOC";
import { getApi } from "../../Repository/Api";
import endPoints from "../../Repository/apiRoutes";
import { Row, Col } from "react-bootstrap";

const UserDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = useCallback(() => {
    getApi({
      url: endPoints.user.getUserDetail(id),
      setLoading,
      setResponse: setData,
    });
  }, [id]);

  useEffect(() => {
    fetchHandler();
  }, [fetchHandler]);

  return (
    <section className="section">
      <FullScreenLoader loading={loading} />

      <div className="multiple-images">
        <img src={data?.data?.image} alt="" />
        <img src={data?.data?.bankDetails?.cheque} alt="" />
        <img src={data?.data?.uploadId?.backImage} alt="" />
        <img src={data?.data?.uploadId?.frontImage} alt="" />
        <img src={data?.data?.uploadId?.panImage} alt="" />
      </div>

      <div className="view-details">
        <Row>
          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Basic Details</h4>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">User Name</p>
              <p className="value"> {data?.data?.fullName} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Email Address</p>
              <p className="value"> {data?.data?.email} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Mobile Number</p>
              <p className="value"> {data?.data?.phone} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">City</p>
              <p className="value"> {data?.data?.city}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">State</p>
              <p className="value"> {data?.data?.state}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Gender</p>
              <p className="value"> {data?.data?.gender} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">DOB</p>
              <p className="value"> {data?.data?.dob} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Wallet</p>
              <p className="value"> {data?.data?.wallet} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Account Verification</p>
              <p className="value">
                {" "}
                {data?.data?.accountVerification ? "Done" : "No"}{" "}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Email Verification</p>
              <p className="value">
                {" "}
                {data?.data?.emailVerification ? "Done" : "No"}{" "}
              </p>
            </div>
          </Col>

          <Col xs={12} md={12}>
            <hr />
          </Col>

          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Bank Details</h4>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Account Holder Name</p>
              <p className="value">
                {" "}
                {data?.data?.bankDetails?.accountHolderName}{" "}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Account Number</p>
              <p className="value">
                {" "}
                {data?.data?.bankDetails?.accountNumber}{" "}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Bank Name</p>
              <p className="value"> {data?.data?.bankDetails?.bankName} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">IFSC Code</p>
              <p className="value"> {data?.data?.bankDetails?.ifscCode} </p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>

          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Aadhar Card Details</h4>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Aadhar Card Number</p>
              <p className="value"> {data?.data?.uploadId?.aadharCardNo} </p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Pan Card Details</h4>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Pan Card Name</p>
              <p className="value"> {data?.data?.uploadId?.panName} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Pan Card Number</p>
              <p className="value"> {data?.data?.uploadId?.panNumber} </p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HOC(UserDetail);
