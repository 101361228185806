/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import { createApi, getApi } from "../../Repository/Api";
import { ClipLoader } from "react-spinners";
import SectionTitle from "../../Component/SectionTitle";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";

const totalOptions = [
  {
    value: "SINGLE",
    label: "Single User",
  },
  {
    value: "ALL",
    label: "All User",
  },
];

const sendOptions = [
  {
    value: "FCM",
    label: "FCM",
  },
  {
    value: "NOTIFICATION",
    label: "NOTIFICATION",
  },
];

const CreateNotification = () => {
  const [total, setTotal] = useState({});
  const [userId, setUserId] = useState({});
  const [matchId, setMatchId] = useState({});
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [date, setDate] = useState("");
  const [users, setUsers] = useState({});
  const [matches, setMatches] = useState({});
  const [loading, setLoading] = useState(false);
  const [sendVia, setSendVia] = useState("");

  const payload = {
    sendTo: "User",
    total: total?.value,
    title,
    content: body,
    date,
    sendVia: sendVia?.value,
    ...(total?.value === "SINGLE" && { userId: userId?.value }),
    ...(matchId?.value && { matchId: matchId?.value }),
  };

  const submitHandler = (e) => {
    e.preventDefault();
    createApi({
      url: "admin/faqs/create",
      payload,
      successMsg: "Created",
      setLoading,
    });
  };

  useEffect(() => {
    getApi({
      url: "admin/userList",
      setResponse: setUsers,
    });
    getApi({
      url: "matches?page=1&limit=200",
      setResponse: setMatches,
    });
  }, []);

  const userOptions =
    users?.data?.length > 0
      ? users?.data?.map((i) => ({
          value: i?._id,
          label: i?.fullName,
        }))
      : [];

  const matchOptions =
    matches?.data?.length > 0
      ? matches?.data?.map((i) => ({
          value: i?._id,
          label: i?.short_title,
        }))
      : [];

  return (
    <section className="section">
      <SectionTitle title={"Send Notification"} />

      <form className="create-form" onSubmit={submitHandler}>
        <Row>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Send to</p>
              <Select
                value={total}
                options={totalOptions}
                placeholder="Select..."
                onChange={(e) => setTotal(e)}
              />
            </div>
          </Col>
          {total?.value === "SINGLE" && (
            <Col xs={12} md={4}>
              <div className="group-input">
                <p>Select User</p>
                <Select
                  value={userId}
                  placeholder="Select User"
                  options={userOptions}
                  onChange={(e) => setUserId(e)}
                />
              </div>
            </Col>
          )}
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Send Via</p>
              <Select
                value={sendVia}
                options={sendOptions}
                placeholder="Select..."
                onChange={(e) => setSendVia(e)}
              />
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Select Match</p>
              <Select
                value={matchId}
                placeholder="Select Match"
                options={matchOptions}
                onChange={(e) => setMatchId(e)}
              />
            </div>
          </Col>
          <Col xs={12} md={8}>
            <div className="group-input">
              <p>Title</p>
              <input
                type="text"
                style={{ maxWidth: "100%" }}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Date</p>
              <input
                type="date"
                style={{ maxWidth: "100%" }}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div className="group-input">
              <p>Body</p>
              <input
                type="text"
                style={{ maxWidth: "100%" }}
                onChange={(e) => setBody(e.target.value)}
                value={body}
              />
            </div>
          </Col>
        </Row>

        <button type="submit">
          {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
        </button>
      </form>
    </section>
  );
};

export default HOC(CreateNotification);
