/** @format */

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assests/Images/logo.png";
import { createApi } from "../Repository/Api";
import { ClipLoader } from "react-spinners";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const payload = {
    email,
    password,
  };

  const saveToken = (res) => {
    localStorage.setItem("token", res);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const additionalFunctions = [
      (res) => saveToken(res?.accessToken),
      () => navigate("/dashboard"),
    ];
    createApi({
      url: "admin/signin",
      payload,
      successMsg: "Welcome Back",
      additionalFunctions,
      setLoading,
    });
  };

  return (
    <section className="Login_section">
      <div className="login_page">
        <img src={logo} alt="" className="logoImg" />

        <div className="content">
          <h4>Welcome to Cricket Fantasy!</h4>
          <p>Please log-in your account</p>
        </div>

        <form onSubmit={submitHandler}>
          <div className="Main_div">
            <p>Email Address / Mobile Number</p>
            <div className="input-group">
              <input type="text" onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>

          <div className="Main_div">
            <p>Password</p>
            <div className="input-group">
              <input
                type={open ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {open ? (
                <i
                  className="fa-solid fa-eye-slash cursor-pointer"
                  onClick={() => setOpen(!open)}
                >
                  {" "}
                </i>
              ) : (
                <i
                  className="fa-solid fa-eye cursor-pointer"
                  onClick={() => setOpen(!open)}
                ></i>
              )}
            </div>
          </div>

          <div className="remember_me">
            <div className="check">
              <input type="checkbox" />
              <p>Remember me</p>
            </div>
            <Link to="/reset-password">Forget Password?</Link>
          </div>

          <button type="submit">
            {loading ? (
              <ClipLoader color="#fff" />
            ) : (
              <>
                LOGIN
                <i className="fa-solid fa-arrow-right ml-2"></i>
              </>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Login;
