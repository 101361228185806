/** @format */
import React, { useState } from "react";
import HOC from "../../Component/layout/HOC";
import { createApi } from "../../Repository/Api";
import { ClipLoader } from "react-spinners";
import SectionTitle from "../../Component/SectionTitle";

const CreateHowPlay = () => {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");

  const payload = {
    description: [description],
  };

  function payloadRemover() {
    setDescription("");
  }

  const additionalFunctions = [payloadRemover];

  const submitHandler = (e) => {
    e.preventDefault();
    createApi({
      url: "admin/AddHowToPlay",
      payload,
      successMsg: "Created",
      additionalFunctions,
      setLoading,
    });
  };

  return (
    <section className="section">
      <SectionTitle title={"How To Play"} />

      <form className="create-form" onSubmit={submitHandler}>
        <div className="group-input">
          <p>Description</p>
          <input
            type="text"
            style={{ maxWidth: "100%" }}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </div>

        <button type="submit">
          {" "}
          {loading ? <ClipLoader color="#fff" /> : "Create New"}{" "}
        </button>
      </form>
    </section>
  );
};
export default HOC(CreateHowPlay);
