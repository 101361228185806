/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";

const SectionTitle = ({ title, isCreate, navigationLink, btnTitle }) => {
  const navigate = useNavigate();
  return isCreate ? (
    <div className="filteration-div">
      <p> {title} </p>
      <div className="Main">
        <button className="create-new" onClick={() => navigate(navigationLink)}>
          {btnTitle ? btnTitle : "Create New"}{" "}
        </button>
      </div>
    </div>
  ) : (
    <div className="section-heading">
      <p className="section-title"> {title} </p>
    </div>
  );
};

export default SectionTitle;
