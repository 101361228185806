/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HOC from "../../Component/layout/HOC";
import FullScreenLoader from "../../Component/FullScreenLoader";
import SectionTitle from "../../Component/SectionTitle";
import { getApi } from "../../Repository/Api";

const ViewResGame = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = useCallback(() => {
    getApi({
      url: `responsible-game/responsible/${id}`,
      setLoading,
      setResponse: setData,
    });
  }, [id]);

  useEffect(() => {
    fetchHandler();
  }, [fetchHandler]);

  return (
    <section className="section">
      <FullScreenLoader loading={loading} />

      <SectionTitle title={"Responsible Game"} />
      <div className="view-details">
        <p className="label">Description</p>
        <p className="value">{data?.data?.title}</p>
        <p className="label">Created At</p>
        <p className="value">{data?.data?.createdAt?.slice(0, 10)}</p>
      </div>
    </section>
  );
};

export default HOC(ViewResGame);
