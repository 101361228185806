/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import SectionTitle from "../../Component/SectionTitle";
import FullScreenLoader from "../../Component/FullScreenLoader";

const HowToPlay = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: "admin/getHowToPlay",
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const deleteHandler = () => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `admin/HowToPlay`,
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  const dataList = response?.data?.description?.map((i, index) => [
    `#${index + 1}`,
    i,
    <DropDown showView={false} deleteHandle={deleteHandler} />,
  ]);

  return (
    <section className="section">
      <div className="boxShad">
        <div className="apply">
          <SectionTitle
            title={"How To Play"}
            navigationLink="/create-how-to-play"
            isCreate={true}
          />
          <FullScreenLoader loading={loading} />

          <TableLayout thead={["SNo", "Description", ""]} tbody={dataList} />
        </div>
      </div>
    </section>
  );
};

export default HOC(HowToPlay);
