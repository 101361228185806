/** @format */

import React, { useState } from "react";
import HOC from "../../Component/layout/HOC";
import { createApi } from "../../Repository/Api";
import { ClipLoader } from "react-spinners";
import SectionTitle from "../../Component/SectionTitle";
import { Col, Row } from "react-bootstrap";

const CreateRefferal = () => {
  const [referredByPrice, setRefferedByPrice] = useState(0);
  const [referredUserPrice, setRefferedUserPrice] = useState(0);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const payload = {
    referredByPrice,
    referredUserPrice,
    status,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    createApi({
      url: "admin/referral-price",
      payload,
      successMsg: "Created",
      setLoading,
    });
  };

  return (
    <section className="section">
      <SectionTitle title={"Referral Price"} />

      <form className="create-form" onSubmit={submitHandler}>
        <Row>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Reffered by Price</p>
              <input
                type="number"
                min={0}
                style={{ maxWidth: "100%" }}
                onChange={(e) => setRefferedByPrice(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Reffered by User Price</p>
              <input
                type="number"
                min={0}
                style={{ maxWidth: "100%" }}
                onChange={(e) => setRefferedUserPrice(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Status</p>
              <select onChange={(e) => setStatus(e.target.value)}>
                <option value=""> Select</option>
                <option value={true}>Active</option>
                <option value={false}>Deactive</option>
              </select>
            </div>
          </Col>
        </Row>

        <button type="submit">
          {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
        </button>
      </form>
    </section>
  );
};

export default HOC(CreateRefferal);
