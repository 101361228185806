/** @format */

import React, { useState } from "react";
import HOC from "../../Component/layout/HOC";
import { createApi } from "../../Repository/Api";
import { ClipLoader } from "react-spinners";
import SectionTitle from "../../Component/SectionTitle";
import endPoints from "../../Repository/apiRoutes";

const CreateBanner = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");

  const fd = new FormData();
  fd.append("name", name);
  fd.append("image", image);

  function payloadRemover() {
    setImage("");
    setName("");
  }

  const additionalFunctions = [payloadRemover];

  const submitHandler = (e) => {
    e.preventDefault();
    createApi({
      url: endPoints.banner.create,
      payload: fd,
      successMsg: "Created",
      additionalFunctions,
      setLoading,
    });
  };

  return (
    <section className="section">
      <SectionTitle title={"Create Banner"} />

      <form className="create-form" onSubmit={submitHandler}>
        <div className="group-input">
          <p>Title</p>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="group-input">
          <p>Image</p>
          <input type="file" onChange={(e) => setImage(e.target.files[0])} />
        </div>

        <button type="submit">
          {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
        </button>
      </form>
    </section>
  );
};

export default HOC(CreateBanner);
