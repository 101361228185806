/** @format */

import React from "react";

const Pagination = ({ page, setPage, setLimit, limit }) => {
  const prev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const next = () => {
    setPage(page + 1);
  };

  return (
    <div className="pagination mt-2">
      <button className="prevBtn" onClick={() => prev()}>
        <i className="fa-solid fa-backward"></i>
      </button>

      <button className="activePage"> {page} </button>

      <button className="nextBtn" onClick={() => next()}>
        {" "}
        <i className="fa-sharp fa-solid fa-forward"></i>
      </button>
      <select onChange={(e) => setLimit(e.target.value)} value={limit}>
        <option value={5}> 5/Page </option>
        <option value={10}> 10/page </option>
        <option value={20}> 20/page </option>
        <option value={50}> 50/page </option>
        <option value={100}> 100/page </option>
      </select>
    </div>
  );
};

export default Pagination;
