/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import FullScreenLoader from "../../Component/FullScreenLoader";
import SectionTitle from "../../Component/SectionTitle";

const CallUs = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: "admin/call-us",
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const deleteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `admin/call-us/${id}`,
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  const dataList = response?.data?.map((i, index) => [
    `#${index + 1}`,
    i?.mobileNumber,
    i?.email,
    <DropDown deleteHandle={() => deleteHandler(i._id)} showView={false} />,
  ]);

  return (
    <>
      <section className="section">
        <div className="boxShad">
          <div className="apply">
            <SectionTitle
              title={"Call Us"}
              isCreate={true}
              navigationLink={"/create-callUs"}
            />
            <FullScreenLoader loading={loading} />

            <TableLayout
              thead={["Sno", "Mobile Number", "Email ID", ""]}
              tbody={dataList}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HOC(CallUs);
