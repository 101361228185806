/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import HOC from "../../Component/layout/HOC";
import FullScreenLoader from "../../Component/FullScreenLoader";
import SectionTitle from "../../Component/SectionTitle";
import { getApi } from "../../Repository/Api";

const ViewContestSetting = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});

  useEffect(() => {
    getApi({
      url: `contestSettings/${id}`,
      setResponse,
      setLoading,
    });
  }, [id]);

  return (
    <section className="section">
      <SectionTitle title={"Contest Setting"} />
      <FullScreenLoader loading={loading} />

      <div className="view-details">
        <Row>
          <Col xs={12} md={4}>
            <p className="label">Contest Type</p>
            <p className="value">{response?.contestSettings?.contestType}</p>
          </Col>
          <Col xs={12} md={4}>
            <p className="label">Prize Pool</p>
            <p className="value">
              <i className="fa-solid fa-indian-rupee-sign"></i>
              {response?.contestSettings?.prizePool}
            </p>
          </Col>
        </Row>
        {response?.contestSettings?.winLevels?.length > 0 && (
          <div className="overflow-X mt-4">
            <table className="colored_table">
              <thead>
                <tr>
                  <th>Sno.</th>
                  <th>Position</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {response?.contestSettings?.winLevels?.map((i, index) => (
                  <tr key={index}>
                    <td> #{index + 1} </td>
                    <td> {i.position} </td>
                    <td> {i.percentage} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </section>
  );
};

export default HOC(ViewContestSetting);
