/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import SectionTitle from "../../Component/SectionTitle";
import { slicingWords } from "../../utils/utils";
import FullScreenLoader from "../../Component/FullScreenLoader";

const AboutUs = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: "static/getAboutUs",
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const deleteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `static/aboutUs/${id}`,
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  const dataList = response?.data?.map((i) => [
    slicingWords(i?.aboutUs),
    <DropDown
      deleteHandle={() => deleteHandler(i._id)}
      viewLink={`/view-about-us/${i._id}`}
    />,
  ]);

  return (
    <section className="section">
      <div className="boxShad">
        <div className="apply">
          <SectionTitle
            title={"About Us"}
            isCreate={true}
            navigationLink="/create-about-us"
          />

          <FullScreenLoader loading={loading} />

          <TableLayout thead={["Description", ""]} tbody={dataList} />
        </div>
      </div>
    </section>
  );
};

export default HOC(AboutUs);
