/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import { getApi, updateApi } from "../../Repository/Api";
import { ClipLoader } from "react-spinners";
import SectionTitle from "../../Component/SectionTitle";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";

const UpdateGst = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [rate, setRate] = useState(1);
  const [data, setData] = useState({});
  const [status, setStatus] = useState({
    value: true,
    label: "Active",
  });

  useEffect(() => {
    getApi({
      url: `admin/gst/${id}`,
      setResponse: setData,
    });
  }, [id]);

  useEffect(() => {
    if (data) {
      setName(data?.data?.name);
      setRate(data?.data?.rate);
      if (data?.data?.status) {
        const filtered = statusOptions.filter(
          (i) => i.value === data?.data?.status
        );
        if (filtered?.[0]) {
          setStatus(filtered?.[0]);
        }
      }
    }
  }, [data]);

  const payload = {
    name,
    rate,
    status: status?.value,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    updateApi({
      url: `admin/gst/${id}`,
      payload,
      successMsg: "Updated !",
      setLoading,
    });
  };

  const statusOptions = [
    {
      value: true,
      label: "Active",
    },
    {
      value: false,
      label: "Deactive",
    },
  ];

  return (
    <section className="section">
      <SectionTitle title={"Update GST"} />

      <form className="create-form" onSubmit={submitHandler}>
        <Row>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Name</p>
              <input
                type="text"
                style={{ maxWidth: "100%" }}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Rate</p>
              <input
                type="number"
                min={0}
                style={{ maxWidth: "100%" }}
                onChange={(e) => setRate(e.target.value)}
                value={rate}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Status</p>
              <Select
                value={status}
                options={statusOptions}
                onChange={(e) => setStatus(e)}
              />
            </div>
          </Col>
        </Row>

        <button type="submit">
          {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
        </button>
      </form>
    </section>
  );
};

export default HOC(UpdateGst);
