/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DropDown from "../../Component/DropDown";
import FullScreenLoader from "../../Component/FullScreenLoader";
import HOC from "../../Component/layout/HOC";
import SectionTitle from "../../Component/SectionTitle";
import TableLayout from "../../Component/TableLayout";
import { getApi } from "../../Repository/Api";
import { formatDate } from "../../utils/utils";

const UserTeam = () => {
  const { id } = useParams();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApi({
      url: `teams/user/${id}`,
      setResponse,
      setLoading,
    });
  }, [id]);

  const dataList = response?.data?.map((i, index) => [
    `#${index + 1}`,
    i?.name,
    i?.contest?.name,
    i?.match,
    i?.totalCredit,
    i?.totalWinningPoint,
    i?.remaningCredit,
    i?.isDistribution ? "Yes" : "No",
    i?.createdAt && formatDate(i?.createdAt),
    <DropDown showDelete={false} viewLink={`/view-team/${i._id}`} />,
  ]);

  const thead = [
    "Sno",
    "Name",
    "Contest",
    "Match",
    "Total Credit",
    "Total Winning Point",
    "Remaining Credit",
    "Distribution",
    "Created At",
    "",
  ];

  return (
    <>
      <FullScreenLoader loading={loading} />
      <section className="section">
        <div className="boxShad">
          <div className="apply">
            <SectionTitle title={`User Team ( ${dataList?.length} )`} />
            <TableLayout thead={thead} tbody={dataList} />
          </div>
        </div>
      </section>
    </>
  );
};

export default HOC(UserTeam);
