/** @format */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApi } from "../../Repository/Api";
import { UpdateProfile } from "../Modals/Modal";

const imgurl =
  "https://img.freepik.com/free-photo/portrait-happy-male-with-broad-smile_176532-8175.jpg?t=st=1714398412~exp=1714402012~hmac=4d65654075b3d25fbe26308388a361f21f76239ad0053e413e3c617a46e8e2a0&w=1380";

const Navbar = ({ hamb, setHamb }) => {
  const [response, setResponse] = useState({});
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const fetchHandler = () => {
    getApi({
      url: "admin/getProfile",
      setResponse,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  return (
    <>
      <UpdateProfile
        show={open}
        handleClose={() => setOpen(false)}
        fetchHandler={fetchHandler}
        prevData={response?.data}
      />
      <div className="Navbar">
        <div className="left-div">
          <i
            className="fa-solid fa-bars-staggered text-2xl font-bold text-gray-900 hover:scale-90 cursor-pointer"
            onClick={() => setHamb(!hamb)}
          ></i>
          <div className="search-bar">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input type="search" placeholder="Search" />
          </div>
        </div>

        {response?.data && (
          <div className="content">
            <i
              className="fa-solid fa-bell cursor-pointer"
              onClick={() => navigate("/notification")}
            ></i>
            <img
              src={response?.data?.image ? response?.data?.image : imgurl}
              alt=""
              onClick={() => setOpen(true)}
              className="userimg cursor-pointer"
            />
            <div>
              <p style={{textTransform : 'capitalize'}} > {response?.data?.fullName} </p>
              <span>Admin</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
