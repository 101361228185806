/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import FullScreenLoader from "../../Component/FullScreenLoader";
import SectionTitle from "../../Component/SectionTitle";

const Offer = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: "offer/program",
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const deleteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `offer/program/${id}`,
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  const dataList = response?.data?.map((i, index) => [
    `#${index + 1}`,
    <img src={i?.image} alt="" className="bannerImg" />,
    i?.name,
    i?.status ? "Active" : "Not Active",
    <DropDown
      deleteHandle={() => deleteHandler(i._id)}
      showView={false}
      showEdit={true}
      editLink={`/update-offer/${i._id}`}
    />,
  ]);

  return (
    <>
      <section className="section">
        <div className="boxShad">
          <div className="apply">
            <SectionTitle
              isCreate={true}
              title={"Offer&Program"}
              navigationLink={"/create-offer"}
            />

            <FullScreenLoader loading={loading} />
            <TableLayout
              thead={["Sno", "Image", "Title", "Status", ""]}
              tbody={dataList}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HOC(Offer);
