/** @format */

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import HOC from "../../Component/layout/HOC";
import { createApi } from "../../Repository/Api";

const CreateContest = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [prizePool, setPrizePool] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [maxParticipants, setMaxParticipants] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("private");
  const [rules, setRules] = useState("");
  const [loading, setLoading] = useState(false);
  const [adminStatus, setAdminStatus] = useState(true);

  const payload = {
    match: id,
    name,
    entryFee,
    prizePool,
    startTime,
    endTime,
    maxParticipants,
    status,
    type,
    rules,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    createApi({
      url: "contests",
      payload,
      successMsg: "Created",
      setLoading,
    });
  };

  return (
    <section className="section">
      <div className="section-heading">
        <p className="section-title">Create Contest</p>
      </div>

      <form className="create-form" onSubmit={submitHandler}>
        <Row>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Contest Name</p>
              <input
                type="text"
                placeholder="write contest name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Entry Fee</p>
              <input
                type="number"
                placeholder="enter entre fee amount"
                min={1}
                onChange={(e) => setEntryFee(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Price pool</p>
              <input
                type="number"
                placeholder="enter price pool amount"
                min={1}
                onChange={(e) => setPrizePool(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Contest Start</p>
              <input
                type="datetime-local"
                onChange={(e) => setStartTime(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Contest End</p>
              <input
                type="datetime-local"
                min={startTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Contest Type</p>
              <select onChange={(e) => setType(e.target.value)}>
                <option value="">select</option>
                <option value="private">Private</option>
                <option value="all">All</option>
              </select>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Max participants</p>
              <input
                type="number"
                min={1}
                onChange={(e) => setMaxParticipants(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Status</p>
              <select onChange={(e) => setStatus(e.target.value)}>
                <option value="">select</option>
                <option value="pending">Pending</option>
                <option value="active">Active</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Admin Status</p>
              <select
                value={adminStatus}
                onChange={(e) => setAdminStatus(e.target.value)}
              >
                <option value="">select</option>
                <option value={true}>Active</option>
                <option value={false}>Deactive</option>
              </select>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div className="group-input">
              <p>Rules</p>
              <input type="text" onChange={(e) => setRules(e.target.value)} />
            </div>
          </Col>
        </Row>

        <button className="mt-5">
          {" "}
          {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
        </button>
      </form>
    </section>
  );
};

export default HOC(CreateContest);
