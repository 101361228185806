/** @format */

import React, { useEffect, useState } from "react";
import {
  usersIcon,
  activeIcon,
  revenueIcon,
  pendingIcon,
} from "../assests/index";
import HOC from "../Component/layout/HOC";
import SectionTitle from "../Component/SectionTitle";
import DashboardCard from "../Component/DashboardCard";
import { getApi } from "../Repository/Api";

const Dashboard = () => {
  const [res, setResponse] = useState({});

  useEffect(() => {
    getApi({
      url: "admin/entityCounts",
      setResponse,
    });
  }, []);

  const data = [
    {
      heading: "Total User",
      count: res?.counts?.userCount,
      icon: usersIcon,
      link: "/user",
    },
    {
      heading: "Total Contest",
      count: res?.counts?.contestCount,
      icon: activeIcon,
      link: "/contest",
    },
    {
      heading: "Total Contest Setting",
      count: res?.counts?.contestSettingCount,
      icon: revenueIcon,
      link: "/tournament",
    },
    {
      heading: "Total Match",
      count: res?.counts?.matchCount,
      icon: pendingIcon,
      link: "/cricket",
    },
  ];
  return (
    <section className="section">
      <SectionTitle title={"Dashboard"} />
      <DashboardCard data={data} />
    </section>
  );
};

export default HOC(Dashboard);
