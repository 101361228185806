/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import FullScreenLoader from "../../Component/FullScreenLoader";
import SectionTitle from "../../Component/SectionTitle";
import endPoints from "../../Repository/apiRoutes";

const Banner = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: endPoints.banner.getAll,
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const deleteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: endPoints.banner.delete(id),
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  const dataList = response?.banners?.map((i, index) => [
    `#${index + 1}`,
    <img src={i?.image} alt="" className="bannerImg" />,
    i?.name,
    <DropDown
      deleteHandle={() => deleteHandler(i._id)}
      showView={false}
      showEdit={false}
    />,
  ]);

  return (
    <>
      <section className="section">
        <div className="boxShad">
          <div className="apply">
            <SectionTitle
              isCreate={true}
              title={"Banner"}
              navigationLink={"/create-banner"}
            />

            <FullScreenLoader loading={loading} />
            <TableLayout
              thead={["Sno", "Image", "Title", "Status", ""]}
              tbody={dataList}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HOC(Banner);
