/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import FullScreenLoader from "../../Component/FullScreenLoader";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import { slicingWords } from "../../utils/utils";
import SectionTitle from "../../Component/SectionTitle";

const FAQ = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: "admin/faqs",
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const deleteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `admin/faqs/${id}`,
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  const dataList = response?.data?.map((i, index) => [
    `#${index + 1}`,
    slicingWords(i.question),
    slicingWords(i.answer),
    i.createdAt?.slice(0, 10),
    <DropDown
      deleteHandle={() => deleteHandler(i._id)}
      viewLink={`/view-faq/${i._id}`}
      showEdit={true}
      editLink={`/edit-faq/${i._id}`}
    />,
  ]);

  return (
    <>
      <section className="section">
        <div className="boxShad">
          <div className="apply">
            <SectionTitle
              title={"FAQ's"}
              isCreate={true}
              navigationLink={"/create-faq"}
            />
            <FullScreenLoader loading={loading} />

            <TableLayout
              thead={["Sno", "Question", "Answer", "Created At", ""]}
              tbody={dataList}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HOC(FAQ);
