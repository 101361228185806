/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import HOC from "../../Component/layout/HOC";
import FullScreenLoader from "../../Component/FullScreenLoader";
import SectionTitle from "../../Component/SectionTitle";
import TableLayout from "../../Component/TableLayout";
import { getApi } from "../../Repository/Api";
import { formatDate } from "../../utils/utils";

const ViewContest = () => {
  const { id } = useParams();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApi({
      url: `contests/${id}`,
      setResponse,
      setLoading,
    });
  }, [id]);

  const participants = response?.data?.participants?.map((i, index) => [
    `#${index + 1}`,
    i?.fullName,
    i?.email,
    i?.phone,
    <Link to={`/user-details/${i._id}`}>View</Link>,
  ]);

  const winners = response?.data?.winners?.map((i, index) => [
    `#${index + 1}`,
    i?.user?.fullName,
    i?.user?.email,
    i?.user?.phone,
    i?.prize,
    i?.position,
    <Link to={`/user-details/${i?.user?._id}`}>View</Link>,
  ]);

  const contestSetting = response?.data?.contestSettings?.winLevels?.map(
    (i, index) => [`#${index + 1}`, i?.position, `${i?.percentage}%`]
  );

  return (
    <section className="section">
      <SectionTitle title={"Contest Detail"} />
      <FullScreenLoader loading={loading} />
      <div className="view-details">
        <Row>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Match Id</p>
              <p className="value"> {response?.data?.match} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Title</p>
              <p className="value">{response?.data?.name}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Entry Fee</p>
              <p className="value">
                <i className="fa-solid fa-indian-rupee-sign"></i>
                {response?.data?.entryFee}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Prize Pool</p>
              <p className="value">
                <i className="fa-solid fa-indian-rupee-sign"></i>
                {response?.data?.prizePool}
              </p>
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div>
              <p className="label">Start Time</p>
              <p className="value">{formatDate(response?.data?.startTime)}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">End Time</p>
              <p className="value">{formatDate(response?.data?.entryFee)}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Status</p>
              <p className="value">{response?.data?.status}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Admin Status</p>
              <p className="value">
                {response?.data?.adminStatus ? "Active" : "Deactive"}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Type</p>
              <p className="value">{response?.data?.type}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Code</p>
              <p className="value">{response?.data?.code}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Max Participants</p>
              <p className="value">{response?.data?.maxParticipants}</p>
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div>
              <p className="label">Created Date</p>
              <p className="value">{formatDate(response?.data?.createdAt)}</p>
            </div>
          </Col>

          <Col xs={12} md={12}>
            <div>
              <p className="label">Rules</p>
              <p className="value">{response?.data?.rules}</p>
            </div>
          </Col>

          <Col xs={12} md={12}>
            <hr />
          </Col>

          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Participants</h4>
          </Col>

          <Col md={12} xs={12} className="mt-2">
            <TableLayout
              thead={["Sno", "Full Name", "Email", "Mobile Number", ""]}
              tbody={participants}
            />
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>

          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Winners</h4>
          </Col>

          <Col md={12} xs={12} className="mt-2">
            <TableLayout
              thead={[
                "Sno",
                "Full Name",
                "Email",
                "Mobile Number",
                "Prize",
                "Position",
                "",
              ]}
              tbody={winners}
            />
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>

          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Contest Settings</h4>
          </Col>
          <Col md={12} xs={12} className="mt-2">
            <TableLayout
              thead={["Sno", "Position", "Percentage"]}
              tbody={contestSetting}
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HOC(ViewContest);
