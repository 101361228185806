/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import SectionTitle from "../../Component/SectionTitle";
import FullScreenLoader from "../../Component/FullScreenLoader";

const Tournament = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: "contestSettings",
      setLoading,
      setResponse,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const deleteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `contestSettings/${id}`,
      successMsg: "Removed",
      additionalFunctions,
    });
  };

  const dataList = response?.data?.map((i, index) => [
    `#${index + 1}`,
    i?.contestType,
    <span>
      <i className="fa-solid fa-indian-rupee-sign"></i>
      {i?.prizePool}
    </span>,
    <DropDown
      deleteHandle={() => deleteHandler(i._id)}
      viewLink={`/view-contest-setting/${i._id}`}
      showEdit={true}
      editLink={`/edit-contest-setting/${i._id}`}
    />,
  ]);

  return (
    <section className="section">
      <div className="boxShad">
        <div className="apply">
          <SectionTitle
            isCreate={true}
            title={"Contest Setting"}
            navigationLink={"/create-treatment"}
          />
          <FullScreenLoader loading={loading} />

          <TableLayout thead={["Sno", "Type", "Price", ""]} tbody={dataList} />
        </div>
      </div>
    </section>
  );
};

export default HOC(Tournament);
