/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi } from "../../Repository/Api";
import { formatDate } from "../../utils/utils";
import FullScreenLoader from "../../Component/FullScreenLoader";
import Pagination from "../../Component/Pagination";
import { Link } from "react-router-dom";
import SectionTitle from "../../Component/SectionTitle";

const statusChecker = (status) => {
  if (status === 1) {
    return <div className="status upcoming">Schduled</div>;
  } else if (status === 2) {
    return <div className="status upcoming">Completed</div>;
  } else if (status === 3) {
    return <div className="status">Live</div>;
  } else {
    return <div className="status past">Abandoned</div>;
  }
};

const Cricket = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("all");
  const [currentMatch, setCurrentMatch] = useState({});
  const [status, setStatus] = useState(1);

  useEffect(() => {
    if (type === "all") {
      getApi({
        url: `matches?page=${page}&limit=${limit}`,
        setResponse,
        setLoading,
      });
    } else {
      getApi({
        url: `current/matches?page=${page}&limit=${limit}&status=${status}`,
        setResponse: setCurrentMatch,
        setLoading,
      });
    }
  }, [limit, page, type, status]);

  const dataList = response?.data?.map((i, index) => [
    `#${index + 1}`,
    i?.match_id,
    i?.title,
    formatDate(i?.date_start),
    formatDate(i?.date_end),
    i?.result,
    statusChecker(i?.status),
    <Link to={`/record/${i?.match_id}`}>View</Link>,
    <Link to={`/create-contest/${i?.match_id}`}>Create Contest</Link>,
    <DropDown showDelete={false} viewLink={`/view-match/${i?.match_id}`} />,
  ]);
  const currentData = currentMatch?.matchIdInfo?.items?.map((i, index) => [
    `#${index + 1}`,
    i?.match_id,
    i?.title,
    formatDate(i?.date_start),
    formatDate(i?.date_end),
    i?.result,
    statusChecker(i?.status),
    <Link to={`/record/${i?.match_id}`}>View</Link>,
    <Link to={`/create-contest/${i?.match_id}`}>Create Contest</Link>,
    <DropDown showDelete={false} viewLink={`/view-match/${i?.match_id}`} />,
  ]);

  return (
    <section className="section">
      <div className="boxShad">
        <div className="apply">
          <SectionTitle title={"Cricket Managment"} />

          <div className="filter-btns">
            <button
              onClick={() => setType("all")}
              className={type === "all" && "active"}
            >
              All
            </button>
            <button
              onClick={() => setType("live")}
              className={type === "live" && "active"}
            >
              Current
            </button>
          </div>

          {type === "live" && (
            <div className="filter-btns">
              <button
                onClick={() => setStatus(1)}
                className={status === 1 && "active"}
              >
                Schduled
              </button>

              <button
                onClick={() => setStatus(2)}
                className={status === 2 && "active"}
              >
                Completed
              </button>
              <button
                onClick={() => setStatus(3)}
                className={status === 3 && "active"}
              >
                Live
              </button>
              <button
                onClick={() => setStatus(4)}
                className={status === 4 && "active"}
              >
                Abandoned
              </button>
            </div>
          )}

          <FullScreenLoader loading={loading} />

          {type === "all" ? (
            <TableLayout
              thead={[
                "Sno.",
                "Match Id",
                "Title",
                "Start date",
                "End Date",
                "Result",
                "Status",
                "Point Record",
                "Contest",
                "",
              ]}
              tbody={dataList}
            />
          ) : (
            <TableLayout
              thead={[
                "Sno.",
                "Match Id",
                "Title",
                "Start date",
                "End Date",
                "Result",
                "Status",
                "Point Record",
                "Contest",
                "",
              ]}
              tbody={currentData}
            />
          )}

          <Pagination
            page={page}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
          />
        </div>
      </div>
    </section>
  );
};

export default HOC(Cricket);
