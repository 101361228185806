/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import FullScreenLoader from "../../Component/FullScreenLoader";
import HOC from "../../Component/layout/HOC";
import SectionTitle from "../../Component/SectionTitle";
import TableLayout from "../../Component/TableLayout";
import { getApi } from "../../Repository/Api";
import { formatDate } from "../../utils/utils";

const ViewTeam = () => {
  const { id } = useParams();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApi({
      url: `teams/${id}`,
      setResponse,
      setLoading,
    });
  }, [id]);

  const players = response?.data?.playersData?.map((i, index) => [
    `#${index + 1}`,
    i?.players,
    i?.name,
    i?.rating,
    i?.playingRole,
  ]);

  const playersPoints = response?.data?.playersPoints?.map((i, index) => [
    `#${index + 1}`,
    i?.players,
    i?.points,
    i?.isPointCount ? "Yes" : "No",
  ]);

  return (
    <section className="section">
      <SectionTitle title={"User Team"} />
      <FullScreenLoader loading={loading} />
      <div className="view-details">
        <Row>
          <Col xs={12} md={4}>
            <div>
              <p className="label">User Name</p>
              <p className="value"> {response?.data?.createdBy?.fullName} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Email Address</p>
              <p className="value"> {response?.data?.createdBy?.email} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Mobile Number</p>
              <p className="value"> {response?.data?.createdBy?.phone} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">View User Details</p>
              <p className="value">
                {" "}
                <Link to={`/user-details/${response?.data?.createdBy?._id}`}>
                  View{" "}
                </Link>{" "}
              </p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Contest</p>
              <p className="value"> {response?.data?.contest?.name} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Entry Fee</p>
              <p className="value">
                {" "}
                <i className="fa-solid fa-indian-rupee-sign"></i>
                {response?.data?.contest?.entryFee}{" "}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Price Pool</p>
              <p className="value">
                {" "}
                <i className="fa-solid fa-indian-rupee-sign"></i>
                {response?.data?.contest?.prizePool}{" "}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">View Contest Details</p>
              <p className="value">
                {" "}
                <Link to={`/view-contest/${response?.data?.contest?._id}`}>
                  View{" "}
                </Link>{" "}
              </p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Title</p>
              <p className="value"> {response?.data?.name} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Match</p>
              <p className="value">{response?.data?.match}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Captain</p>
              <p className="value">{response?.data?.captain}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Vice-Captain</p>
              <p className="value">{response?.data?.viceCaptain}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Total Credit</p>
              <p className="value">{response?.data?.totalCredit}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Total Winning Point</p>
              <p className="value">{response?.data?.totalWinningPoint}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Remaining Credit</p>
              <p className="value">{response?.data?.remaningCredit}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Distribution</p>
              <p className="value">
                {response?.data?.isDistribution ? "Yes" : "No"}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Created At</p>
              <p className="value">
                {" "}
                {response?.data?.createdAt &&
                  formatDate(response?.data?.createdAt)}{" "}
              </p>
            </div>
          </Col>

          <Col xs={12} md={12}>
            <hr />
          </Col>

          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Players</h4>
          </Col>

          <Col md={12} xs={12} className="mt-2">
            <TableLayout
              thead={["Sno", "ID", "Name", "Rating", "Role"]}
              tbody={players}
            />
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>

          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Players Point</h4>
          </Col>

          <Col md={12} xs={12} className="mt-2">
            <TableLayout
              thead={["Sno", "ID", "Points", "isPointCount"]}
              tbody={playersPoints}
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HOC(ViewTeam);
