/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import HOC from "../../Component/layout/HOC";
import FullScreenLoader from "../../Component/FullScreenLoader";
import SectionTitle from "../../Component/SectionTitle";
import TableLayout from "../../Component/TableLayout";
import { getApi } from "../../Repository/Api";
import {
  formatDate,
  GameStateCode,
  InningStatusCode,
  MatchFormat,
  TossDecisionCode,
  WagonCommentoryCode,
} from "../../utils/utils";

const statusChecker = (status) => {
  if (status === "1") {
    return <p className="value">Schduled</p>;
  } else if (status === "2") {
    return <p className="value">Completed</p>;
  } else if (status === "3") {
    return <p className="value">Live</p>;
  } else {
    return <p className="value">Abandoned</p>;
  }
};

const isValidChecker = (value) => {
  if (value === "true" || value === true) {
    return <p className="value">Yes</p>;
  } else {
    return <p className="value">No</p>;
  }
};

const isValidCheckerforTable = (value) => {
  if (value === "true" || value === true) {
    return "Yes";
  } else {
    return "No";
  }
};

const ViewMatch = () => {
  const { id } = useParams();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [squads, setSquads] = useState({});

  useEffect(() => {
    getApi({
      url: `matches/${id}/info`,
      setResponse,
      setLoading,
    });
    getApi({
      url: `series/fantacy/${id}/Squard`,
      setResponse: setSquads,
    });
  }, [id]);

  const teamAPlayer = squads?.fantacySquard?.teama?.squads?.map((i, index) => [
    `#${index + 1}`,
    i?.player_id,
    i?.name,
    isValidCheckerforTable(i?.substitute),
    isValidCheckerforTable(i?.out),
    isValidCheckerforTable(i?.in),
    i?.role_str,
    i?.role,
    isValidCheckerforTable(i?.playing11),
  ]);
  const teamBPlayers = squads?.fantacySquard?.teamb?.squads?.map((i, index) => [
    `#${index + 1}`,
    i?.player_id,
    i?.name,
    isValidCheckerforTable(i?.substitute),
    isValidCheckerforTable(i?.out),
    isValidCheckerforTable(i?.in),
    i?.role_str,
    i?.role,
    isValidCheckerforTable(i?.playing11),
  ]);

  const teams = squads?.fantacySquard?.teams?.map((i, index) => [
    `#${index + 1}`,
    i?.tid,
    i?.title,
    i?.abbr,
    i?.alt_name,
    i?.type,
    <a href={i?.thumb_url} target="_blank" rel="noreferrer">
      {" "}
      View{" "}
    </a>,
    <a href={i?.logo_url} target="_blank" rel="noreferrer">
      {" "}
      View{" "}
    </a>,
    i?.country,
    i?.sex,
  ]);

  const teamHead = [
    "Sno.",
    "ID",
    "Name",
    "Substitute",
    "Out",
    "In",
    "Role Str",
    "Role",
    "Playing 11",
  ];

  return (
    <section className="section">
      <SectionTitle title={"Cricket Management"} />
      <FullScreenLoader loading={loading} />

      <div className="view-details">
        <Row>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Match Id</p>
              <p className="value"> {response?.matchIdInfo?.match_id} </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Title</p>
              <p className="value">{response?.matchIdInfo?.title}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Short Title</p>
              <p className="value">{response?.matchIdInfo?.short_title}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Sub-Title</p>
              <p className="value">{response?.matchIdInfo?.subtitle}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Match Number</p>
              <p className="value">{response?.matchIdInfo?.match_number}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Format</p>
              <p className="value">
                {MatchFormat(response?.matchIdInfo?.format)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Format Str</p>
              <p className="value">{response?.matchIdInfo?.format_str}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Title</p>
              <p className="value">{response?.matchIdInfo?.title}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Status</p>
              {statusChecker(response?.matchIdInfo?.status)}
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Status Str</p>
              <p className="value">{response?.matchIdInfo?.status_str}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Status Note</p>
              <p className="value">{response?.matchIdInfo?.status_note}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Verified</p>
              {isValidChecker(response?.matchIdInfo?.verified)}
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Pre Squad</p>
              {isValidChecker(response?.matchIdInfo?.pre_squad)}
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Ods Available</p>
              {isValidChecker(response?.matchIdInfo?.odds_available)}
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Game state</p>
              <p className="value">
                {GameStateCode(response?.matchIdInfo?.game_state)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Game state str</p>
              <p className="value">{response?.matchIdInfo?.game_state_str}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Start Date</p>
              <p className="value">
                {formatDate(response?.matchIdInfo?.date_start)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">End Date</p>
              <p className="value">
                {formatDate(response?.matchIdInfo?.date_end)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Start Timestamp</p>
              <p className="value">{response?.matchIdInfo?.timestamp_start}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">End Timestamp</p>
              <p className="value">{response?.matchIdInfo?.timestamp_end}</p>
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div>
              <p className="label">Start Date 1st</p>
              <p className="value">
                {formatDate(response?.matchIdInfo?.date_start_ist)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">End Dat 1ste</p>
              <p className="value">
                {formatDate(response?.matchIdInfo?.date_end_ist)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Umpires</p>
              <p className="value">{response?.matchIdInfo?.umpires}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Refree</p>
              <p className="value">{response?.matchIdInfo?.referee}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Euaqtion</p>
              <p className="value">{response?.matchIdInfo?.equation}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Live</p>
              <p className="value">{response?.matchIdInfo?.live}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Result</p>
              <p className="value">{response?.matchIdInfo?.result}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Result Type </p>
              <p className="value">{response?.matchIdInfo?.result_type}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Win Margin</p>
              <p className="value">{response?.matchIdInfo?.win_margin}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Winning Team ID</p>
              <p className="value">{response?.matchIdInfo?.winning_team_id}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Commentary</p>
              <p className="value">
                {WagonCommentoryCode(response?.matchIdInfo?.commentary)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Wagon</p>
              <p className="value">
                {WagonCommentoryCode(response?.matchIdInfo?.wagon)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Latest Inning Number</p>
              <p className="value">
                {InningStatusCode(response?.matchIdInfo?.latest_inning_number)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Presquad Time</p>
              <p className="value">{response?.matchIdInfo?.presquad_time}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Verify Team</p>
              <p className="value">
                {formatDate(response?.matchIdInfo?.verify_time)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Match DLS Affected</p>
              <p className="value">
                {response?.matchIdInfo?.match_dls_affected}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Live Inning Number</p>
              <p className="value">
                {InningStatusCode(response?.matchIdInfo?.live_inning_number)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Day</p>
              <p className="value">{response?.matchIdInfo?.day}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Session</p>
              <p className="value">{response?.matchIdInfo?.session}</p>
            </div>
          </Col>

          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Competition</h4>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">CID</p>
              <p className="value">{response?.matchIdInfo?.competition?.cid}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Title</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.title}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Abbr</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.abbr}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Type</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.type}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Category</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.category}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Match Format</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.match_format}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Season</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.season}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Status</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.status}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Date Start</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.datestart}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Date End</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.dateend}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Country</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.country}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Total Matches</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.total_matches}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Total Rounds</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.total_rounds}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Total Teams</p>
              <p className="value">
                {response?.matchIdInfo?.competition?.total_teams}
              </p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Team A</h4>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Team ID</p>
              <p className="value">{response?.matchIdInfo?.teama?.team_id}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Name</p>
              <p className="value">{response?.matchIdInfo?.teama?.name}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Short Name</p>
              <p className="value">
                {response?.matchIdInfo?.teama?.short_name}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Logo</p>
              <p className="value">
                <a
                  href={response?.matchIdInfo?.teama?.logo_url}
                  target="_blank" rel="noreferrer"
                >
                  View
                </a>
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Thumb</p>
              <p className="value">
                <a
                  href={response?.matchIdInfo?.teama?.thumb_url}
                  target="_blank" rel="noreferrer"
                >
                  View
                </a>
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Full Scores</p>
              <p className="value">
                {response?.matchIdInfo?.teama?.scores_full}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Scores</p>
              <p className="value">{response?.matchIdInfo?.teama?.scores}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Overs</p>
              <p className="value">{response?.matchIdInfo?.teama?.overs}</p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Team B</h4>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Team ID</p>
              <p className="value">{response?.matchIdInfo?.teamb?.team_id}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Name</p>
              <p className="value">{response?.matchIdInfo?.teamb?.name}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Short Name</p>
              <p className="value">
                {response?.matchIdInfo?.teamb?.short_name}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Logo</p>
              <p className="value">
                <a
                  href={response?.matchIdInfo?.teamb?.logo_url}
                  target="_blank" rel="noreferrer"
                >
                  View
                </a>
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Thumb</p>
              <p className="value">
                <a
                  href={response?.matchIdInfo?.teamb?.thumb_url}
                  target="_blank" rel="noreferrer"
                >
                  View
                </a>
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Full Scores</p>
              <p className="value">
                {response?.matchIdInfo?.teamb?.scores_full}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Scores</p>
              <p className="value">{response?.matchIdInfo?.teamb?.scores}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Overs</p>
              <p className="value">{response?.matchIdInfo?.teamb?.overs}</p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Venue</h4>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Venue ID</p>
              <p className="value">{response?.matchIdInfo?.venue?.venue_id}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Name</p>
              <p className="value">{response?.matchIdInfo?.venue?.name}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Location</p>
              <p className="value">{response?.matchIdInfo?.venue?.location}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Country</p>
              <p className="value">{response?.matchIdInfo?.venue?.country}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Timezone</p>
              <p className="value">{response?.matchIdInfo?.venue?.timezone}</p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Toss</h4>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Text</p>
              <p className="value">{response?.matchIdInfo?.toss?.text}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Decision</p>
              <p className="value">
                {TossDecisionCode(response?.matchIdInfo?.toss?.decision)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Winner</p>
              <p className="value">{response?.matchIdInfo?.toss?.winner}</p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col xs={12} md={12}>
            <h4 className="fw-bold mt-4">Squads</h4>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Teams</p>
            </div>
          </Col>
          <Col md={12} xs={12} className="mt-2">
            <TableLayout
              thead={[
                "Sno",
                "ID",
                "Title",
                "Abbr",
                "Alt Name",
                "Type",
                "Thumb ",
                "Logo",
                "Country",
                "Sex",
              ]}
              tbody={teams}
            />
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Team A</p>
            </div>
          </Col>
          <Col md={12} xs={12} className="mt-2">
            <TableLayout
              thead={teamHead}
              tbody={teamAPlayer}
            />
          </Col>
          <Col xs={12} md={4}>
            <div>
              <p className="label">Team B</p>
            </div>
          </Col>
          <Col md={12} xs={12} className="mt-2">
            <TableLayout thead={teamHead} tbody={teamBPlayers} />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HOC(ViewMatch);
