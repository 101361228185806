/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import { getApi } from "../../Repository/Api";
import SectionTitle from "../../Component/SectionTitle";
import { useParams } from "react-router-dom";

const UserTransaction = () => {
  const { id } = useParams();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    getApi({
      url: `admin/allTransactionUser/${id}`,
      setResponse,
      setLoading,
    });
  }, [id]);

  useEffect(() => {
    if (response?.data?.[0]?.user?.fullName) {
      setUserName(response.data[0].user.fullName);
    }
  }, [response]);

  const dataList = response?.data?.map((i, index) => [
    `#${index + 1}`,
    i?.date?.slice(0, 10),
    i?.type,
    <span>
      <i className="fa-solid fa-indian-rupee-sign"></i>
      {i?.amount}
    </span>,
  ]);

  return (
    <section className="section">
      <div className="boxShad">
        <div className="apply">
          <div style={{ textTransform: "capitalize" }}>
            <SectionTitle title={`All ${userName} Transactions`} />
          </div>

          <TableLayout
            thead={["Sno", "Date", "Type", "Amount"]}
            loading={loading}
            tbody={dataList}
          />
        </div>
      </div>
    </section>
  );
};

export default HOC(UserTransaction);
