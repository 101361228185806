/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";

const DashboardCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="dashboard-cards">
      {data?.map((i, index) => (
        <div
          className="card cursor-pointer"
          onClick={() => navigate(i.link)}
          key={index}
        >
          <div className="above">
            <div className="left">
              <p className="heading"> {i.heading} </p>
              <p className="count"> {i.count} </p>
            </div>
            <img src={i.icon} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardCard;
