/** @format */

import React, { useCallback, useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import { getApi, updateApi } from "../../Repository/Api";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import SectionTitle from "../../Component/SectionTitle";

const EditFaq = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const payload = {
    question,
    answer,
  };

  const fetchHandler = useCallback(() => {
    getApi({
      url: `admin/faqs/${id}`,
      setResponse: setData,
    });
  }, [id]);

  useEffect(() => {
    fetchHandler();
  }, [fetchHandler]);

  useEffect(() => {
    if (data) {
      setQuestion(data?.data?.question);
      setAnswer(data?.data?.answer);
    }
  }, [data]);

  const additionalFunctions = [fetchHandler];

  const submitHandler = (e) => {
    e.preventDefault();
    updateApi({
      url: `admin/faqs/${id}`,
      payload,
      successMsg: "Created",
      additionalFunctions,
      setLoading,
    });
  };

  return (
    <section className="section">
      <SectionTitle title={"Edit FAQ's"} />

      <form className="create-form" onSubmit={submitHandler}>
        <div className="group-input">
          <p>Question</p>
          <input
            type="text"
            style={{ maxWidth: "100%" }}
            onChange={(e) => setQuestion(e.target.value)}
            value={question}
          />
        </div>
        <div className="group-input">
          <p>Answer</p>
          <input
            type="text"
            style={{ maxWidth: "100%" }}
            onChange={(e) => setAnswer(e.target.value)}
            value={answer}
          />
        </div>

        <button type="submit">
          {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
        </button>
      </form>
    </section>
  );
};

export default HOC(EditFaq);
