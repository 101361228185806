/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import { getApi } from "../../Repository/Api";
import SectionTitle from "../../Component/SectionTitle";
import FullScreenLoader from "../../Component/FullScreenLoader";
import { Link } from "react-router-dom";
import endPoints from "../../Repository/apiRoutes";

const Notification = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: endPoints.notification.getAll,
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const dataList = response?.data?.map((i) => [
    i?.title,
    i?.body,
    <Link to={`/user-details/${i?.recipient?._id}`}>
      {" "}
      {i?.recipient?.fullName}{" "}
    </Link>,
    <Link to={`/view-match/${i?.matchId?.match_id}`}>
      {" "}
      {i?.matchId?.short_title}{" "}
    </Link>,
    i?.date,
  ]);

  return (
    <section className="section">
      <FullScreenLoader loading={loading} />
      <div className="boxShad">
        <div className="apply">
          <SectionTitle
            title={"Notification"}
            isCreate={true}
            navigationLink="/create-notification"
          />
          <TableLayout
            thead={["Title", "Body", "User", "Match", "Date"]}
            tbody={dataList}
          />
        </div>
      </div>
    </section>
  );
};

export default HOC(Notification);
