/** @format */

import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";

const DropDown = ({
  viewLink,
  deleteHandle,
  showEdit,
  showView = true,
  showDelete = true,
  editLink,
}) => {
  const navigate = useNavigate();

  const items = [
    showEdit && {
      label: (
        <div className="icon-merged" onClick={() => navigate(editLink)}>
          <i className="fa-solid fa-pen"></i>
          Edit
        </div>
      ),
      key: "0",
    },
    showView && {
      label: (
        <div className="icon-merged" onClick={() => navigate(viewLink)}>
          <i className="fa-regular fa-user"></i>
          View
        </div>
      ),
      key: "3",
    },
    showDelete && {
      label: (
        <div className="icon-merged delete" onClick={() => deleteHandle()}>
          <i className="fa-regular fa-trash-can"></i>
          Delete
        </div>
      ),
      key: "4",
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={["click"]}
    >
      <div className="option-dropdown">
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </div>
    </Dropdown>
  );
};

export default DropDown;
