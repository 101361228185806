/** @format */

import { Alert } from "react-bootstrap";

const NoData = () => {
  return (
    <div className="p-3 pt-0">
      <Alert>No data found !</Alert>
    </div>
  );
};

export default NoData;
