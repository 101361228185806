/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import { getApi, updateApi } from "../../Repository/Api";
import { ClipLoader } from "react-spinners";
import SectionTitle from "../../Component/SectionTitle";
import { useParams } from "react-router-dom";

const UpdateOffer = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);
  const [image, setImage] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    getApi({
      url: `offer/program/${id}`,
      setResponse: setData,
    });
  }, [id]);

  useEffect(() => {
    if (data) {
      setStatus(data?.data?.status);
      setName(data?.data?.name);
    }
  }, [data]);

  const fd = new FormData();
  fd.append("name", name);
  fd.append("image", image);
  fd.append("status", status);

  function payloadRemover() {
    setImage("");
    setName("");
  }

  const additionalFunctions = [payloadRemover];

  const submitHandler = (e) => {
    e.preventDefault();
    updateApi({
      url: `offer/program/${id}`,
      payload: fd,
      successMsg: "Created",
      additionalFunctions,
      setLoading,
    });
  };

  return (
    <section className="section">
      <SectionTitle title={"Offer&Program"} />

      <form className="create-form" onSubmit={submitHandler}>
        <div className="group-input">
          <p>Title</p>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="group-input">
          <p>Image</p>
          <input type="file" onChange={(e) => setImage(e.target.files[0])} />
        </div>
        <div className="group-input">
          <p>Status</p>
          <select onChange={(e) => setStatus(e.target.value)} value={status}>
            <option value="">Select status</option>
            <option value={true}> Active </option>
            <option value={false}> Not Active </option>
          </select>
        </div>

        <button type="submit">
          {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
        </button>
      </form>
    </section>
  );
};

export default HOC(UpdateOffer);
