/** @format */

import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { getApi, updateApi } from "../../Repository/Api";

export const UpdateSettingModal = ({ show, handleClose, id }) => {
  const [response, setResponse] = useState({});
  const [contestSettings, setContestSettings] = useState("");
  const [loading, setLoading] = useState(false);

  const payload = {
    contestSettings,
  };

  const additionalFunctions = [handleClose];

  const submitHandler = (e) => {
    e.preventDefault();
    updateApi({
      url: `contests-update/${id}`,
      payload,
      successMsg: "Updated",
      setLoading,
      additionalFunctions,
    });
  };

  useEffect(() => {
    if (show) {
      getApi({
        url: "contestSettings",
        setResponse,
      });
    }
  }, [show]);


  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Contest Setting
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3">
            <Form.Select
              required
              onChange={(e) => setContestSettings(e.target.value)}
            >
              <option value="">Select</option>
              {response?.data?.map((i, index) => (
                <option value={i._id} key={index}>
                  {" "}
                  {i.contestType}{" "}
                  <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                  {i.prizePool}{" "}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <button className="submitBtn" type="submit">
            {" "}
            {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const UpdateProfile = ({
  show,
  handleClose,
  fetchHandler,
  prevData,
}) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);

  const fd = new FormData();
  fd.append("fullName", fullName);
  fd.append("email", email);
  fd.append("phone", phone);
  fd.append("image", image);

  const additionalFunctions = [handleClose, fetchHandler];

  const submitHandler = (e) => {
    e.preventDefault();
    updateApi({
      url: `admin/updateProfile`,
      payload: fd,
      successMsg: "Updated",
      setLoading,
      additionalFunctions,
    });
  };

  useEffect(() => {
    if (prevData) {
      setFullName(prevData?.fullName);
      setEmail(prevData?.email);
      setPhone(prevData?.phone);
    }
  }, [prevData]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3">
            <Form.Group className="mb-3">
              <Form.Label>Profile Image</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </Form.Group>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="tel"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
          </Form.Group>

          <button className="submitBtn" type="submit">
            {" "}
            {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
