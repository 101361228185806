/** @format */

import React, { useCallback, useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import { debouncedSetQuery, formatDate } from "../../utils/utils";
import FullScreenLoader from "../../Component/FullScreenLoader";
import SectionTitle from "../../Component/SectionTitle";
import Pagination from "../../Component/Pagination";
import { UpdateSettingModal } from "../../Component/Modals/Modal";
import endPoints from "../../Repository/apiRoutes";

const Contest = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");

  const fetchHandler = useCallback(() => {
    getApi({
      url: endPoints.contest.getAll({ query, page, limit }),
      setResponse: setData,
      setLoading,
    });
  }, [page, limit, query]);

  useEffect(() => {
    fetchHandler();
  }, [fetchHandler]);

  const deleteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `contests/${id}`,
      successMsg: "Removed",
      additionalFunctions,
    });
  };

  console.log(data?.data);

  const userList = data?.data?.docs?.map((i, index) => [
    `#${index + 1}`,
    i?.code,
    i?.name,
    formatDate(i?.startTime),
    formatDate(i?.endTime),
    <span>
      <i className="fa-solid fa-indian-rupee-sign"></i>
      {i?.entryFee}
    </span>,
    i?.matchId?.match_id,
    i?.createdAt?.slice(0, 10),
    <div className="status upcoming"> {i.status} </div>,
    <button
      type="button"
      onClick={() => {
        setId(i._id);
        setOpen(true);
      }}
      style={{ color: "blue", textDecoration: "underline" }}
    >
      Update
    </button>,
    <DropDown
      viewLink={`/view-contest/${i._id}`}
      deleteHandle={() => deleteHandler(i._id)}
      showEdit={true}
      editLink={`/edit-contest/${i?._id}`}
    />,
  ]);
  return (
    <>
      <UpdateSettingModal
        show={open}
        handleClose={() => setOpen(false)}
        id={id}
      />

      <FullScreenLoader loading={loading} />
      <section className="section">
        <div className="boxShad">
          <div className="apply">
            <SectionTitle title={`All Contests`} />
            <div className="search-container-with">
              <div className="search-container">
                <input
                  type={"search"}
                  placeholder="Search"
                  onChange={(e) => debouncedSetQuery(e.target.value, setQuery)}
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
            </div>
            <TableLayout
              thead={[
                "SNo.",
                "Code",
                "Contest name",
                "Start Time",
                "End Time",
                "Entry",
                "Match ID",
                "Created At",
                "Status",
                "Contest Setting",
                "",
              ]}
              tbody={userList}
            />
            <Pagination
              page={page}
              setPage={setPage}
              setLimit={setLimit}
              limit={limit}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HOC(Contest);
