/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import FullScreenLoader from "../../Component/FullScreenLoader";
import { slicingWords } from "../../utils/utils";
import SectionTitle from "../../Component/SectionTitle";

const FantasyHelp = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: "fantacy-help/getFantacySelfHelp",
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const deleteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `fantacy-help/fantacy/${id}`,
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  const dataList = response?.data?.map((i) => [
    slicingWords(i?.title),
    <DropDown
      deleteHandle={() => deleteHandler(i._id)}
      viewLink={`/view-fantasy-help/${i._id}`}
    />,
  ]);

  return (
    <>
      <section className="section">
        <div className="boxShad">
          <div className="apply">
            <SectionTitle
              isCreate={true}
              title={"Fantasy Self Help"}
              navigationLink={"/create-fantasy-help"}
            />
            <FullScreenLoader loading={loading} />

            <TableLayout thead={["Description", ""]} tbody={dataList} />
          </div>
        </div>
      </section>
    </>
  );
};

export default HOC(FantasyHelp);
