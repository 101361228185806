/** @format */

import React from "react";
import { RiCloseLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { MdDashboardCustomize } from "react-icons/md";
import logo from "../../assests/Images/logo.png";

const Sidebar = ({ hamb, setHamb }) => {
  const location = useLocation();

  let nav = [
    {
      icon: <MdDashboardCustomize className="text-xl mr-3 rounded-full " />,
      link: "/dashboard",
      name: "Dashboard",
    },
    {
      icon: <i className="fa-solid fa-user  text-xl mr-3 rounded-full"></i>,
      link: "/user",
      name: "User Management",
    },
    {
      icon: (
        <i className="fa-solid fa-baseball-bat-ball text-xl mr-3 rounded-full"></i>
      ),
      link: "/cricket",
      name: "Cricket",
    },
    {
      icon: <i className="fa-solid fa-coins text-xl mr-3 rounded-full"></i>,
      link: "/contest",
      name: "Contest",
    },
    {
      icon: <i className="fa-solid fa-gear  text-xl mr-3 rounded-full"></i>,
      link: "/tournament",
      name: "Contest Setting",
    },
    {
      icon: (
        <i className="fa-solid fa-building-columns  text-xl mr-3 rounded-full"></i>
      ),
      link: "/transaction",
      name: "Transaction",
    },
    {
      icon: (
        <i className="fa-solid fa-credit-card  text-xl mr-3 rounded-full"></i>
      ),
      link: "/credit-transaction",
      name: "Credit Transaction",
    },
    {
      icon: (
        <i className="fa-solid fa-cash-register  text-xl mr-3 rounded-full"></i>
      ),
      link: "/debit-transaction",
      name: "Debit Transaction",
    },
    {
      icon: (
        <i className="fa-solid fa-percentage  text-xl mr-3 rounded-full"></i>
      ),
      link: "/tds",
      name: "TDS",
    },
    {
      icon: (
        <i className="fa-solid fa-money-bill  text-xl mr-3 rounded-full"></i>
      ),
      link: "/gst",
      name: "GST",
    },
    {
      icon: <i className="fa-solid fa-bell  text-xl mr-3 rounded-full"></i>,
      link: "/notification",
      name: "Notification",
    },
    {
      icon: <i className="fa-solid fa-ticket  text-xl mr-3 rounded-full"></i>,
      link: "/referral-price",
      name: "Referral Price",
    },
    {
      icon: <i className="fa-solid fa-gamepad  text-xl mr-3 rounded-full"></i>,
      link: "/how-to-play",
      name: "How To Play",
    },
    {
      icon: (
        <i className="fa-brands fa-fantasy-flight-games text-xl mr-3 rounded-full"></i>
      ),
      link: "/responsible-game",
      name: "Responsible Game",
    },
    {
      icon: (
        <i className="fa-solid fa-address-card  text-xl mr-3 rounded-full"></i>
      ),
      link: "/about-us",
      name: "About Us",
    },
    {
      icon: (
        <i className="fa-solid fa-shield-halved  text-xl mr-3 rounded-full"></i>
      ),
      link: "/privacy-policy",
      name: "Privacy Policy",
    },
    {
      icon: <i className="fa-solid fa-folder  text-xl mr-3 rounded-full"></i>,
      link: "/terms",
      name: "Terms",
    },
    {
      icon: (
        <i className="fa-solid fa-file-contract  text-xl mr-3 rounded-full"></i>
      ),
      link: "/legality",
      name: "Legality",
    },
    {
      icon: <i className="fa-solid fa-question  text-xl mr-3 rounded-full"></i>,
      link: "/faq",
      name: "FAQ",
    },
    {
      icon: (
        <i className="fa-solid fa-phone-volume  text-xl mr-3 rounded-full"></i>
      ),
      link: "/call-us",
      name: "Call Us",
    },
    {
      icon: (
        <i className="fa-solid fa-circle-info  text-xl mr-3 rounded-full"></i>
      ),
      link: "/fantasy-help",
      name: "Fantasy Self Help",
    },

    {
      icon: <i className="fa-solid fa-image text-xl mr-3 rounded-full"></i>,
      link: "/offer",
      name: "Offer&Program",
    },
    {
      icon: <i className="fa-solid fa-image text-xl mr-3 rounded-full"></i>,
      link: "/banner",
      name: "Banner",
    },
  ];

  let nav2 = [
    {
      icon: (
        <i className="fa-solid fa-arrow-right-from-bracket text-xl mr-3 rounded-full"></i>
      ),
      link: "/",
      name: "Logout",
    },
  ];

  return (
    <>
      <aside className="sidebar">
        <div className="w-full md:hidden relative  p-2 mb-4">
          <RiCloseLine
            onClick={() => setHamb(!hamb)}
            className="text-3xl  absolute top-2 sm:hover:rotate-[228deg] transition-transform font-bold right-2 sm:hover:text-[22px] text-[rgb(241,146,46)] cursor-pointer"
          />
        </div>
        <img src={logo} alt="" className="logoImg" />
        <div className="nav-link">
          <nav>
            {nav?.map((nav, index) => (
              <Link
                to={nav.link}
                key={index}
                className={location.pathname === nav.link && "active"}
              >
                {nav.icon} {nav.name}
              </Link>
            ))}
          </nav>
          <nav>
            {nav2?.map((nav, index) => (
              <Link
                to={nav.link}
                key={index}
                className={location.pathname === nav.link && "active"}
              >
                {nav.icon} {nav.name}
              </Link>
            ))}
          </nav>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
