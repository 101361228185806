/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Component/layout/HOC";
import TableLayout from "../../Component/TableLayout";
import DropDown from "../../Component/DropDown";
import { getApi, removeApi } from "../../Repository/Api";
import SectionTitle from "../../Component/SectionTitle";

const GetTDS = () => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: "admin/tds",
      setResponse,
      setLoading,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const deleteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `admin/tds/${id}`,
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  const dataList = response?.data?.map((i) => [
    i?.name,
    <span>
      {i?.rate}{" "}
      <i className="fa-solid fa-percent"></i>
    </span>,
    i?.status ? "Active" : "Deactive",
    <DropDown
      deleteHandle={() => deleteHandler(i._id)}
      showEdit={true}
      editLink={`/edit-tds/${i?._id}`}
      showView={false}
    />,
  ]);

  return (
    <section className="section">
      <div className="boxShad">
        <div className="apply">
          <SectionTitle
            title={"TDS"}
            isCreate={true}
            navigationLink="/create-tds"
          />

          <TableLayout
            thead={["Name", "Rate", "Status", ""]}
            tbody={dataList}
            loading={loading}
          />
        </div>
      </div>
    </section>
  );
};

export default HOC(GetTDS);
