/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import logo from "../assests/Images/logo.png";
import { createApi } from "../Repository/Api";

const ResetPassword = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [id, setId] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const SendOtp = (e) => {
    e.preventDefault();
    const additionalFunctions = [() => setStep(2)];
    createApi({
      url: "admin/forgetPassword",
      payload: { email },
      successMsg: "OTP sended successfully !",
      additionalFunctions,
      setLoading,
    });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    const additionalFunctions = [
      (res) => setId(res?.data?.userId),
      () => setStep(3),
    ];
    createApi({
      url: "admin/forgotVerifyotp",
      payload: { email, otp },
      additionalFunctions,
      setLoading,
    });
  };

  const resetPassword = (e) => {
    e.preventDefault();
    const additionalFunctions = [() => navigate("/")];
    createApi({
      url: `admin/changePassword/${id}`,
      payload: { newPassword, confirmPassword },
      additionalFunctions,
      setLoading,
    });
  };

  return (
    <>
      <section className="Login_section">
        {step === 1 && (
          <div className="login_page">
            <img src={logo} alt="" className="logoImg" />

            <div className="content">
              <h4>Enter Mobile or Email</h4>
              <p>You’ll receive a verification code shortly.</p>
            </div>

            <form onSubmit={SendOtp}>
              <div className="Main_div">
                <p>Mobile</p>
                <div className="input-group">
                  <input
                    type="tel"
                    placeholder=""
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="or-div">
                <div></div>
                <p>or</p>
                <div></div>
              </div>
              <div className="Main_div">
                <p>Email</p>
                <div className="input-group">
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <button type="submit">
                {loading ? (
                  <ClipLoader color="#fff" />
                ) : (
                  <span>
                    Send Code
                    <i className="fa-solid fa-arrow-right ml-2"></i>
                  </span>
                )}
              </button>
            </form>
          </div>
        )}

        {step === 2 && (
          <div className="login_page">
            <img src={logo} alt="" className="logoImg" />

            <div className="content">
              <h4>Enter Verification code.</h4>
              <p>Verification code sent to your mobile number or email</p>
            </div>

            <form onSubmit={verifyOtp}>
              <div className="Main_div">
                <p>Verification Code</p>
                <div className="input-group">
                  <input
                    type="tel"
                    placeholder=""
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
              </div>

              <button type="submit">
                {loading ? (
                  <ClipLoader color="#fff" />
                ) : (
                  <span>
                    NEXT
                    <i className="fa-solid fa-arrow-right ml-2"></i>
                  </span>
                )}
              </button>
            </form>
          </div>
        )}
        {step === 3 && (
          <div className="login_page">
            <img src={logo} alt="" className="logoImg" />

            <div className="content">
              <h4>Set New Password</h4>
              <p>
                Use combination of uppercase letter(XYZ), lowercase letter(xyz),
                numbers(1234) and symbols(!@&).
              </p>
            </div>

            <form onSubmit={resetPassword}>
              <div className="Main_div">
                <p>New Password</p>
                <div className="input-group">
                  <input
                    type="tel"
                    placeholder=""
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="Main_div">
                <p>Confirm Password</p>
                <div className="input-group">
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>

              <button type="submit">
                {loading ? (
                  <ClipLoader color="#fff" />
                ) : (
                  <span>
                    SET PASSWORD{" "}
                    <i className="fa-solid fa-arrow-right ml-2"></i>
                  </span>
                )}
              </button>
            </form>
          </div>
        )}
      </section>
    </>
  );
};

export default ResetPassword;
