/** @format */

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import HOC from "../../Component/layout/HOC";
import { createApi } from "../../Repository/Api";

const CreateTournament = () => {
  const [contestType, setContestType] = useState("");
  const [prizePool, setPrizePool] = useState("");
  const [winLevels, setWinLevels] = useState([]);
  const [position, setPosition] = useState("");
  const [percentage, setPencentage] = useState("");
  const [loading, setLoading] = useState(false);

  const listPayload = {
    position,
    percentage,
  };

  const addMore = () => {
    setWinLevels((prev) => [...prev, listPayload]);
    setPosition("");
    setPencentage("");
  };

  const removeList = (index) => {
    const filteredData = winLevels?.filter((_, i) => i !== index);
    setWinLevels(filteredData);
  };

  const payload = {
    contestType,
    prizePool,
    winLevels,
  };

  function payloadRemover() {
    setWinLevels("");
    setContestType("");
    setPrizePool("");
  }

  const submitHandler = (e) => {
    e.preventDefault();
    const additionalFunctions = [payloadRemover];
    createApi({
      url: `contestSettings`,
      payload,
      successMsg: "Created",
      additionalFunctions,
      setLoading,
    });
  };

  return (
    <section className="section">
      <div className="section-heading">
        <p className="section-title">Create Contest Setting</p>
      </div>

      <form className="create-form" onSubmit={submitHandler}>
        <Row>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Contest Type </p>
              <input
                type="text"
                value={contestType}
                onChange={(e) => setContestType(e.target.value)}
                placeholder="write contest name"
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Prize Pool</p>
              <input
                type="number"
                min={1}
                value={prizePool}
                onChange={(e) => setPrizePool(e.target.value)}
                placeholder="enter price pool amount"
              />
            </div>
          </Col>
          <Col xs={12} md={4}></Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Position</p>
              <input
                type="number"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Percentage</p>
              <input
                type="number"
                value={percentage}
                onChange={(e) => setPencentage(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <button type="button" onClick={addMore}>
          Add More Positions
        </button>
        {winLevels?.length > 0 && (
          <div className="overflow-X">
            <table className="colored_table">
              <thead>
                <tr>
                  <th>Sno.</th>
                  <th>Position</th>
                  <th>Percentage</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {winLevels?.map((i, index) => (
                  <tr key={index}>
                    <td> #{index + 1} </td>
                    <td> {i.position} </td>
                    <td> {i.percentage} </td>
                    <td>
                      <i
                        className="fa-solid fa-trash"
                        onClick={() => removeList(index)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <button className="mt-5">
          {loading ? <ClipLoader color="#fff" /> : "Create New"}
        </button>
      </form>
    </section>
  );
};

export default HOC(CreateTournament);
