/** @format */

import React, { useState } from "react";
import HOC from "../../Component/layout/HOC";
import { createApi } from "../../Repository/Api";
import { ClipLoader } from "react-spinners";
import SectionTitle from "../../Component/SectionTitle";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";

const CreateGst = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [rate, setRate] = useState(1);
  const [status, setStatus] = useState({
    value: true,
    label: "Active",
  });

  const payload = {
    name,
    rate,
    status: status?.value,
  };

  function payloadRemover() {
    setName("");
    setRate(1);
  }

  const additionalFunctions = [payloadRemover];

  const submitHandler = (e) => {
    e.preventDefault();
    createApi({
      url: "admin/gst",
      payload,
      successMsg: "Created",
      additionalFunctions,
      setLoading,
    });
  };

  const statusOptions = [
    {
      value: true,
      label: "Active",
    },
    {
      value: false,
      label: "Deactive",
    },
  ];

  return (
    <section className="section">
      <SectionTitle title={"Create GST"} />

      <form className="create-form" onSubmit={submitHandler}>
        <Row>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Name</p>
              <input
                type="text"
                style={{ maxWidth: "100%" }}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Rate</p>
              <input
                type="number"
                min={0}
                style={{ maxWidth: "100%" }}
                onChange={(e) => setRate(e.target.value)}
                value={rate}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="group-input">
              <p>Status</p>
              <Select
                value={status}
                options={statusOptions}
                onChange={(e) => setStatus(e)}
              />
            </div>
          </Col>
        </Row>

        <button type="submit">
          {loading ? <ClipLoader color="#fff" /> : "Submit"}{" "}
        </button>
      </form>
    </section>
  );
};

export default HOC(CreateGst);
