/** @format */

export const valueReturner = ({ value, label }) => {
  if (value) {
    return (
      <div className="group">
        <p className="title">{label} </p>
        <p className="value">{value} </p>
      </div>
    );
  }
};
export const slicingWords = (value) => {
  if (value?.length > 50) {
    return `${value?.slice(0, 59)}...`;
  } else {
    return value;
  }
};
export function formatDate(time) {
  const updateTime = new Date(time);
  const timezoneOffset = updateTime?.getTimezoneOffset();
  const adjustedTime = new Date(updateTime.getTime() + timezoneOffset * 60000);
  const month = adjustedTime.getMonth() + 1;
  const day = adjustedTime.getDate();
  const year = adjustedTime.getFullYear();
  const hasAll = month && year && day;
  const TimeForm = adjustedTime?.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return (
    hasAll &&
    `${month <= 9 ? `0${month}` : month}-${
      day <= 9 ? `0${day}` : day
    }-${year} ${TimeForm} `
  );
}
export function MatchFormat(code) {
  const formats = {
    1: "ODI (One Day International)",
    2: "TEST",
    3: "T20I (Twenty20 International)",
    4: "List A (Limited Over Domestic Match)",
    5: "First Class",
    6: "T20(Domestic)",
    7: "Women ODI",
    8: "Women T20",
    9: "Youth ODI",
    10: "Youth T20",
    11: "Other",
    12: "Other List A",
    13: "Other 1st Class",
    14: "Other T20",
    15: "Youth Test",
    16: "Woman Test",
    17: "T10",
    18: "T100",
    19: "Women T100",
  };

  return formats[code] || code;
}
export function GameStateCode(code) {
  const formats = {
    0: "Default",
    1: "Starts Shortly",
    2: "Toss",
    3: "Play Ongoing",
    4: "Delayed",
    5: "Drinks Break",
    6: "Innings Break",
    7: "Stumps",
    8: "Lunch Break",
    9: "Tea Break",
    10: "Match Start Delay",
    11: "Rain Delay",
    12: "Dinner",
    13: "Strategic Timeout",
    14: "Technical Issue",
    15: "Bad Light",
    16: "Match Interrupted",
  };

  return formats[code] || code;
}
export function WagonCommentoryCode(code) {
  const formats = {
    0: "Not  Available",
    1: "Available",
    2: "Not  Available",
  };

  return formats[code] || code;
}
export function InningStatusCode(code) {
  const formats = {
    1: "Scheduled",
    2: "Completed",
    3: "Live",
    4: "Abandoned",
  };

  return formats[code] || code;
}
export function TossDecisionCode(code) {
  const formats = {
    1: "Batting",
    2: "Fielding",
  };

  return formats[code] || code;
}

export const debouncedSetQuery = (term, setQuery) => {
  clearTimeout(debouncedSetQuery.timeoutId);
  debouncedSetQuery.timeoutId = setTimeout(() => {
    setQuery(term);
  }, 500);
};
